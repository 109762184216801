import React, {useCallback, useEffect, useState} from 'react';
import Modal from "react-modal";
import headerStyles from '../../styles/reviewPage/reviewPageContainer.module.css';
import styles from '../../styles/reviewPage/userReviewModal.module.css'
import styled from "styled-components";
import ReviewTableRow from "./ReviewTableRow";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";
import {ReactComponent as XClose} from "../../images/x-close.svg";

import {backgroundScrollStart, backgroundScrollStop} from "../../util/modalUtill";
import {request} from "../../util/request";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  gap: 10px;
  position: absolute;
  width: 1140px;
  height: 560px;
  background: #FFFFFF;
  border-radius: 12px;
  overflow: scroll;
  border: 1px solid #EBEDF0;
`;
const UserReviewModal = ({
                             userId, setUserId, nickname
                         }) => {

    const [isDateDesc, setIsDateDesc] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState('latest');
    const [totalPage, setTotalPage] = useState(1);
    const onClickReviewDesc = useCallback(() => {
        setPage(0);
        setSort('latest');
        setIsDateDesc(true);
    }, [reviews]);
    const onClickReviewAsc = useCallback(() => {
        setPage(0);
        setSort('oldest');
        setIsDateDesc(false);
    }, [reviews])
    const getReviews = useCallback(() => {
        if (page >= totalPage) {
            return;
        }
        request.get('/api/v1/admin/lecture/user-review', {
            params: {userId, page: page, sort}
        })
            .then(({status, data}) => {
                if (status === 200) {
                    setReviews([...reviews, ...data.reviews])
                    setPage(data.currentPage + 1);
                    setTotalPage(data.totalPage);
                }
            })
    }, [page, totalPage, userId, sort]);

    const getReviewFirst = useCallback(() => {
        if (page >= totalPage) {
            return;
        }
        request.get('/api/v1/admin/lecture/user-review', {
            params: {userId, page: 0, sort}
        })
            .then(({status, data}) => {
                if (status === 200) {
                    setReviews([...data.reviews])
                    setPage(data.currentPage + 1);
                    setTotalPage(data.totalPage);
                }
            })
    }, [userId, sort])

    const removeReviewInList = useCallback((id) => {
        setReviews(reviews.filter((item) => item.reviewId !== id));
    }, [reviews])

    useEffect(() => {
        if (userId != null) {
            getReviewFirst();
        }

    }, [userId])

    useEffect(() => {
        backgroundScrollStop()
        return backgroundScrollStart();
    }, []);
    const closeModal = useCallback(() => {
        setUserId(null);
    }, [setUserId])

    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.key === 'Escape') {
                closeModal()
            }
        };
        window.addEventListener("keyup", escKeyModalClose);
        return () => window.removeEventListener("keyup", escKeyModalClose);
    }, []);

    return (
        <SModal
            style={{
                overlay: {
                    backgroundColor: "#00000066"
                }}}
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={() => setUserId(null)}
            shouldCloseOnOverlayClick={true}
        >
            <div>
                <div className={styles.container}>
                    <div className={styles.top}>
                        {nickname}님이 등록한 리뷰
                    </div>
                </div>
                <div
                    onClick={() => setUserId(null)}
                    className={styles.xClose}>
                    <XClose></XClose>

                </div>
                <div className={styles.table}>
                    <div className={headerStyles.tableHeader}>
                        <div className={headerStyles.dateColumn}>
                            <div>날짜</div>
                            <div className={headerStyles.sort}>
                                <div
                                    className={isDateDesc === null ? '' : !isDateDesc ? headerStyles.active : ''}
                                >
                                    <ChevronUp
                                        onClick={() => {
                                            onClickReviewAsc()
                                        }}
                                    ></ChevronUp>
                                </div>
                                <div
                                    className={isDateDesc === null ? '' : isDateDesc ? headerStyles.active : ''}
                                >
                                    <ChevronDown
                                        onClick={() => {
                                            onClickReviewDesc()
                                        }}
                                    ></ChevronDown>
                                </div>
                            </div>
                        </div>
                        <div className={headerStyles.tableHeaderColumn}>
                            사용자
                        </div>
                        <div className={headerStyles.tableHeaderColumn}>
                            평점
                        </div>
                        <div className={headerStyles.tableHeaderColumn}
                             style={{width: '400px'}}
                        >
                            리뷰 내용
                        </div>
                        <div className={headerStyles.tableHeaderColumn}>
                            좋아요수
                        </div>
                        <div className={headerStyles.tableHeaderColumn}>
                            신고수
                        </div>
                    </div>
                    {reviews.map(({
                                      reviewId,
                                      userId,
                                      lectureId,
                                      date,
                                      nickname,
                                      grade,
                                      lectureTitle,
                                      reviewContent,
                                      recommendationCount,
                                      reportCount,
                                      pinned,
                                      postCourseTarget
                                  }) => (
                        <ReviewTableRow
                            key={reviewId}
                            reviewId={reviewId}
                            userId={userId}
                            lectureId={lectureId}
                            date={date}
                            nickname={nickname}
                            grade={grade}
                            lectureTitle={lectureTitle}
                            reviewContent={reviewContent}
                            recommendationCount={recommendationCount}
                            reportCount={reportCount}
                            removeReviewInList={removeReviewInList}
                            postCourseTarget={postCourseTarget}
                            pinned={pinned}
                        />
                    ))}
                    {
                        page < totalPage && page > 0 &&
                        <button className={styles.moreBtnContainer}
                                onClick={() => getReviews()}
                        >
                            <div className={styles.moreBtn}>
                                <div>더보기</div>
                                <ChevronDown/>
                            </div>

                        </button>
                    }
                </div>
            </div>
        </SModal>
    );
};

export default UserReviewModal;