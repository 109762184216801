import React, {useCallback, useEffect, useState} from 'react';
import Header from "../common/Header";
import '../../styles/reviewList/reviewListContainer.css'
import Review from "./review";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {request} from "../../util/request";

const ReviewListContainer = ({
                                 lectureId
                             }) => {
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [sort, setSort] = useState('latest');
    const [reviews, setReview] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        request.get(`/api/v1/admin/lecture/${lectureId}/review`, {
            params: {page: 0, sort}
        })
            .then(({status, data}) => {
                if (status === 200) {
                    const {totalCount, currentPage, totalPage, reviews} = data;
                    setTotalCount(totalCount);
                    setCurrentPage(currentPage);
                    setTotalPage(totalPage);
                    setReview([...reviews]);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [sort, lectureId]);

    const onClickReviewMore = useCallback(() => {
        setIsLoading(true);
        if (totalPage > currentPage + 1) {
            request.get( `/api/v1/admin/lecture/${lectureId}/review`, {
                params: {page: currentPage + 1, sort}
            })
                .then(({status, data}) => {
                    if (status === 200) {
                        const {totalCount, currentPage, totalPage} = data;
                        setTotalCount(totalCount);
                        setCurrentPage(currentPage);
                        setTotalPage(totalPage);
                        setReview([...reviews, ...data.reviews]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }

    }, [currentPage, totalPage, sort, lectureId, reviews])

    const onRemoveReview = useCallback((id)=>{
        setReview(reviews.filter(user => user.id !== id));
        setTotalCount(prevState => prevState-1)
    }, [reviews])
    return (
        <div className={'reviewListContainer'}>
            <div className={'reviewListHeader'}>
                <div className={'reviewHeaderLeft'}>
                    <Header name={'리뷰'}/>
                    <div className={'reviewCountContainer'}>
                        <div>총</div>
                        <div className={'reviewTotalCount'}>{totalCount.toLocaleString()}</div>
                    </div>
                </div>
                <select className={'reviewSortSelect'} onChange={(e) => {
                    setSort(e.target.value)
                }}>
                    <option value={'latest'}>최신순</option>
                    <option value={'like-desc'}>좋아요순</option>
                    <option value={'declaration-desc'}>신고순</option>
                    <option value={'grade-desc'}>평점 높은순</option>
                    <option value={'grade-asc'}>평점 낮은순</option>
                </select>
            </div>
            {reviews.map(({id, grade, nickname, date, recommendationCount, reportCount, body, edited, position, year, postCourseTarget, pinned}) => (
                <Review
                    key={id}
                    lectureId={lectureId}
                    id={id}
                    grade={grade}
                    nickname={nickname}
                    date={date}
                    isEdited={edited}
                    recommendationCount={recommendationCount}
                    reportCount={reportCount}
                    body={body}
                    position={position}
                    year={year}
                    postCourseTarget={postCourseTarget}
                    pinned={pinned}
                    onRemove={onRemoveReview}
                />
            ))}
            {totalPage > currentPage + 1 &&
                <div className={'moreReviewButtonContainer'}

                >
                    <button className={'moreReviewButton'}
                            disabled={isLoading}
                            onClick={() => {
                                onClickReviewMore()
                            }}
                    >
                        <div>더보기</div>
                        <ChevronDown/>
                    </button>
                </div>
            }

        </div>
    );
};

export default ReviewListContainer;