import React from 'react';
import '../../styles/common/formInputText.css';

const FormInputText = ({name, placeholder, unit, type, setValue, value, maxLength = 1000, width=null}) => {
    return (<div className={'formInputTextContainer'}>
        <div className={'formInputTextName'}>{name}</div>
        <div className={'formInputContainer'}>
            <input style={{width: width? width : null}} className={'formInputText'} placeholder={placeholder} type={type}
                   value={value}
                   maxLength={maxLength}
                   onChange={(e) => {
                       setValue(e.target.value)
                   }}/>
            {unit && <div className={'formInputTextUnit'}>{unit}</div>}
        </div>
    </div>);
};

export default FormInputText;