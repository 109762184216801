import React from 'react';
import {ReactComponent as ChevronRight} from '../../../images/chevron-right.svg';
import '../../../styles/home/lectureRequestAlarm/lectureRequestCard.css';
import {Link} from "react-router-dom";

const LectureRequestCard = ({
                                nickName, date, lectureId
                            }) => {
    return (
        <div className={'lectureRequestCard'}>
            <div className={'lectureRequestInfo'}>
                <div className={'lectureRequestTime'}>{date}</div>
                <div className={'lectureRequestText'}>[{nickName}]님이 강의를 신청했어요.</div>
            </div>
            <div className={'chevronIcon'}>
                <Link to={'/lectures/applications/' + lectureId}>
                    <ChevronRight/>
                </Link>
            </div>
        </div>
    );
};

export default LectureRequestCard;