import styles from '../../../styles/notice/eventEditor/eventEditor.module.css';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {request} from "../../../util/request";
import {convertToRaw, EditorState, ContentState} from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import FormInputText from "../../common/FormInputText";
import Calender from "../../home/dashboard/Calender";
import {
    toDateByString,
    toDateByStringTime,
    toStringByFormatting,
    toStringByFormattingTime
} from "../../../util/dateUtil";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import htmlToDraft from 'html-to-draftjs';


const EventEditor = () => {
    const location = useLocation();
    const [id, setId] = useState(null);
    const navigate = useNavigate();
    const imageInput = useRef();
    const [fileName, setFileName] = useState('배너 이미지(png)를 등록해주세요. (390*400px)');
    const [file, setFile] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const now = new Date();
    const maxDate = (()=>{
        const date = new Date();
        date.setFullYear(now.getFullYear() + 10);
        return date;
    })()
    const [startDate, setStartDate] = useState((()=>{
        const date = new Date();
        date.setDate(now.getDate()+1);
        date.setHours(8, 0);
        return date;
    })());
    const [endDate, setEndDate] = useState((()=>{
        const date = new Date();
        date.setDate(now.getDate() + 8);
        date.setHours(11, 30);
        return date;
    })())
    const [isLoading, setLoading] = useState(false);
    const date = {
        state: {startDate, endDate},
        action: {setStartDate, setEndDate}
    };
    const [title, setTitle] = useState('');
    const changeFile = useCallback(() => {
        if (imageInput.current.files.length) {
            console.log(imageInput.current.files[0])
            setFileName(imageInput.current.files[0].name);
            setFile(imageInput.current.files[0])
        }
    }, [imageInput])
    const onClickImageUpload = useCallback(() => {
            imageInput.current.click();
        }, [imageInput]
    )
    const onChangeEditor = useCallback((state) => {
        console.log(draftToHtml(convertToRaw(state.getCurrentContent())))
        setEditorState(state);
    }, [])
    const uploadImageCallback = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return request.post("/api/v1/admin/event/image", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(({status, data}) => {
                return {data: {link: `https://on-review-images.s3.ap-northeast-2.amazonaws.com/event/body/${data.id}.${data.extension}`}};
            })
            .catch(() => {
                alert('업로드 실패')
            })
    }
    useEffect(() => {
        const id = qs.parse(location.search, {
            ignoreQueryPrefix: true
        }).id;
        setId(id);
    }, [location])
    useEffect(() => {
        if (id != null) {
            request.get('/api/v1/admin/event/' + id)
                .then(({status, data}) => {
                    const {title, body, startDate, endDate} = data;
                    if (status === 200) {
                        setTitle(title.replace('(광고)', ''));

                        const blocksFromHtml = htmlToDraft(body);
                        const {contentBlocks, entityMap} = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        const editorState = EditorState.createWithContent(contentState);
                        setEditorState(editorState)
                        setStartDate(toDateByStringTime(startDate, '.'))
                        setEndDate(toDateByStringTime(endDate, '.'))
                    }
                })
                .catch(() => {
                })
        }
    }, [id])

    const postEvent = useCallback(() => {
        if (!(file && title && editorState && startDate)) return;
        setLoading(true);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', title);
        formData.append('body', html);
        formData.append('startDate', toStringByFormattingTime(startDate));
        if(endDate){
            formData.append('endDate', toStringByFormattingTime(endDate));
        }
        request.post('/api/v1/admin/event', formData)
            .then(({status}) => {
                if (status === 201) {
                    alert('이벤트가 등록됐습니다!')
                    navigate('/notice-faq');
                } else {
                    alert('등록 실패했습니다. 다시 입력해주세요!')
                }
            })
            .catch(() => {
                alert('등록 실패했습니다. 다시 입력해주세요!')
            })
            .finally(() => {
                setLoading(false);
            })

    }, [file, title, startDate, editorState])

    const deleteEvent = useCallback(() => {
        setLoading(true);
        request.delete('/api/v1/admin/event/' + id)
            .then(() => {
                alert('이벤트가 삭제됐습니다!')
                navigate('/notice-faq');
            })
            .catch(() => {
                alert("삭제에 실패했습니다")
            })
            .finally(() => {
                setLoading(false);
            })
    }, [id])

    const updateEvent = useCallback(() => {
        if (!(title && editorState && startDate)) return;
        setLoading(true);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const formData = new FormData();
        if(file != null){
            formData.append('file', file);
        }
        formData.append('title', title);
        formData.append('body', html);
        formData.append('startDate', toStringByFormattingTime(startDate));
        if(endDate){
            formData.append('endDate', toStringByFormattingTime(endDate));
        }
        request.put('/api/v1/admin/event/' + id, formData)
            .then(({status}) => {
                if (status === 200) {
                    alert('이벤트가 수정 됐습니다!')
                    navigate('/notice-faq');
                } else {
                    alert('수정 실패했습니다. 다시 입력해주세요!')
                }
            })
            .catch(() => {
                alert('수정 실패했습니다. 다시 입력해주세요!')
            })
            .finally(() => {
                setLoading(false);
            })
    }, [id, title, editorState, startDate, endDate, file]);


    return (
        <div className={styles.container}>
            <div className={styles.mainBannerContainer}>
                <div className={styles.header}>메인 배너 이미지</div>
                <div className={styles.imageContainer}>
                    <input
                        type="file" style={{display: "none"}} ref={imageInput}
                        onChange={() => {
                            changeFile();
                        }}
                    />
                    <div>{fileName}</div>
                    <button
                        className={'platformModalFileButton'}
                        onClick={onClickImageUpload}>찾아보기
                    </button>
                </div>
            </div>
            <div className={styles.inputContainer}>
                <FormInputText name={'제목'} placeholder={'공지 제목을 입력해주세요.'} type={'text'}
                               value={title}
                               setValue={setTitle}
                               width={'600px'}
                />
                <div className={styles.calenderContainer}>
                    <div className={styles.header}>노출 기간</div>
                    <Calender dateState={date.state} dateAction={date.action} maxDate={maxDate} timeSelect={true}/>
                </div>
            </div>
            <div className={styles.editor}>
                <div className={styles.header}>내용</div>
                <div className={styles.editorContainer}>
                    <Editor
                        editorStyle={{height: '400px'}}
                        editorState={editorState}
                        onEditorStateChange={onChangeEditor}
                        wrapperClassName="wrapper-class"
                        editorClassName={styles.textContainer}
                        toolbarClassName={styles.toolbar}
                        toolbar={{
                            image: {
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: true,
                                uploadCallback: uploadImageCallback,
                                alt: {present: true, mandatory: true},
                                previewImage: true,
                                defaultSize: {
                                    width: 'auto',
                                    height: 'auto'
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div className={`${styles.buttonContainer}`}>
                {
                    !id ?
                        <>
                            <button
                                className={styles.exitButton}
                                disabled={isLoading}
                                onClick={() => navigate('/notice-faq')}
                            >취소하기
                            </button>
                            <button
                                className={`${styles.registerButton} ${!(title && file && editorState) ? '' : styles.active}`}
                                onClick={() => postEvent()}
                                disabled={isLoading}
                            >등록하기
                            </button>
                        </>
                        :
                        <>
                            <button
                                className={styles.exitButton}
                                disabled={isLoading}
                                onClick={() => deleteEvent()}
                            >삭제하기
                            </button>
                            <button
                                className={`${styles.registerButton} ${!(title && editorState) ? '' : styles.active}`}
                                onClick={() => updateEvent()}
                                disabled={isLoading}
                            >수정하기
                            </button>
                        </>
                }


            </div>
        </div>
    );
};

export default EventEditor;