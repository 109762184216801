import React, {useCallback, useState} from 'react';
import styles from "../../../styles/noticeAndFaqPage/faq/faqTableRow.module.css";
import {Link} from "react-router-dom";
import {ReactComponent as ChevronDown} from "../../../images/chevron-down.svg";
import {ReactComponent as ChevroUp} from "../../../images/chevron-up.svg";
import {ReactComponent as ToggleOn} from "../../../images/toggle-on.svg";
import {ReactComponent as ToggleOff} from "../../../images/toggle-off.svg";
import {request} from "../../../util/request";
const FaqTableRow = ({id, createdAt, publisher, category, categoryId, answer, question, displayed}) => {
    const [isFolded, setFolded] = useState(true);
    const [isDisplayed, setDisplayed] = useState(displayed);
    const onClickChevron = useCallback(()=>{
        setFolded(!isFolded);
    }, [isFolded])
    const onClickToggle = useCallback(()=>{
        setDisplayed(!isDisplayed);
        request.put(`/api/v1/admin/faq/${id}/displayed`)
            .then(()=>{})
            .catch(()=>{})
    }, [isDisplayed])
    return (

            <tr className={styles.row}>
                <td className={styles.column}>
                    {createdAt}
                </td>
                <td className={styles.column}>
                    {publisher}
                </td>
                <td className={styles.column}>
                    {category}
                </td>
                <td className={styles.column}>
                    <div className={styles.titleContainer}>
                        <Link to={`/faq/editor?id=${id}&category=${categoryId}`}
                              style={{textDecoration: 'none'}}>
                        <div className={styles.questionContainer}>
                            <div className={styles.question}>{question}</div>
                            {!isFolded && <div className={styles.answer}>{answer}</div>}
                        </div>
                        </Link>
                        <div className={styles.chevron} onClick={()=>onClickChevron()}>
                            {isFolded?<ChevronDown/>:<ChevroUp/>}
                        </div>
                    </div>
                </td>
                <td className={styles.column}>
                    <div className={styles.toggle} onClick={()=>onClickToggle()}>
                        {isDisplayed?<ToggleOn/>:<ToggleOff/>}
                    </div>
                </td>
            </tr>
    );
};

export default FaqTableRow;