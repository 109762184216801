import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../../styles/noticeAndFaqPage/notice/noticeList.module.css'
import {ReactComponent as Search} from "../../../images/search.svg";
import NoticeTableRow from "./NoticeTableRow";
import {request} from "../../../util/request";

const NoticeList = () => {
    const [count, setCount] = useState(0);
    const [query, setQuery] = useState('');
    const [sort, setSort] = useState('latest');
    const [notices, setNotices] = useState([]);
    useEffect(() => {
        request.get('/api/v1/admin/notice', {
            params: {
                sort: 'latest'
            }
        })
            .then(({status, data}) => {
                if (status === 200) {
                    setCount(data.count)
                    setNotices(data.notices);
                }
            })
            .catch(() => {

            })
    }, [])

    useEffect(()=>{
        search()
    },[sort])

    const search = useCallback(() => {
        request.get('/api/v1/admin/notice', {
            params: {sort, q:query}
        }).then(({status, data}) => {
            if (status === 200) {
                setCount(data.count)
                setNotices(data.notices);
            }
        })
            .catch(() => {
            })
    }, [query, sort])
    const enter = useCallback((e) => {
        if (e.key === 'Enter') {
            search()
        }
    }, [search])


    return (
        <div>
            <div className={styles.searchContainer}>
                <div className={styles.searchInputContainer}>
                    <div className={styles.searchInput}>
                        <input placeholder={'제목, 내용 키워드를 검색하세요.'}
                               onKeyDown={(e)=>enter(e)}
                               value={query}
                               onChange={(e) => {
                                   setQuery(e.target.value)
                               }}
                        />
                        <Search/>
                    </div>
                    <div className={styles.countContainer}>
                        총
                        <span className={styles.count}>{' ' + count.toLocaleString() + ' '}</span>
                        개
                    </div>
                </div>

                <select
                    className={styles.sort}
                    onChange={e => setSort(e.target.value)}
                    value={sort}
                >
                    <option value={"latest"}>최신순</option>
                    <option value={"oldest"}>등록순</option>
                </select>
            </div>
            <table className={styles.table}>
                <thead>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.tableHeaderColumn}>
                        등록일
                    </th>

                    <th className={styles.tableHeaderColumn}>
                        등록자
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        카테고리
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        제목
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        노출기간
                    </th>
                </tr>
                </thead>
                <tbody>
                {notices.map(({id, createdAt, publisher, category, categoryId, title, startedAt, endedAt}) => (
                    <NoticeTableRow
                        id={id}
                        createdAt={createdAt}
                        publisher={publisher}
                        category={category}
                        categoryId={categoryId}
                        title={title}
                        startedAt={startedAt}
                        endedAt={endedAt}
                    />))
                }
                </tbody>
            </table>
        </div>
    );
};

export default NoticeList;