import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import Modal from "react-modal";
import styles from '../../styles/search/keywordAddModal.module.css'
import {ReactComponent as XClose} from "../../images/x-close.svg";
import {request} from "../../util/request";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const KeywordAddModal = ({
    isOpen, setLoadState, setIsOpen
                         }) => {
    const [keyword, setKeyword] = useState('');
    const closeModal = useCallback(()=>{
        setIsOpen(false);
        setKeyword('')
    },[])
    const registerKeyword = useCallback(()=>{
        request.post( '/api/v1/admin/search/recommendation', {
            keyword
        })
            .then(({status})=>{
                if(status == 201){
                    setLoadState(false);
                    setIsOpen(false);
                    setKeyword('');
                }else {
                    alert('등록 실패');
                }
            })
            .catch((err)=>{
                console.log(err)
                alert('등록 실패');
            })
    }, [keyword])
    // const onEnter = useCallback((e)=>{
    //     if(e.key === "Enter"){
    //         registerKeyword()
    //     }
    // },[])
    return (
        <SModal
            style={{
                overlay: {
                    backgroundColor: "#00000066"
                }}}
            isOpen={isOpen}
        >
            <div className={styles.closeButton}
                onClick={()=>closeModal()}
            >
                <XClose/>
            </div>
                <div className={styles.header}>추천 검색어 추가하기</div>
                <div className={styles.inputContainer}>
                    <div className={styles.inputHeader}>검색어 이름</div>
                    <input
                        autoFocus={true}
                        onChange={(e)=>setKeyword(e.target.value)}
                        // onKeyUp={(e)=>onEnter(e)}
                        className={styles.inputBox}
                        value={keyword}
                        placeholder={'이름을 입력해주세요.'}/>

                </div>
                <div className={styles.buttonContainer}>
                    <button
                        className={styles.exitButton}
                        onClick={()=>closeModal()}
                    >취소</button>
                    <button
                        className={`${styles.addButton} ${keyword?styles.active:''}`}
                        onClick={()=>registerKeyword()}
                        disabled={keyword == ''}
                    >추가하기</button>
                </div>

        </SModal>
    );
};

export default KeywordAddModal;