import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/search/keywordRankingTable.module.css'
import {ReactComponent as ChevronRight} from "../../images/chevron-right.svg";
import {ReactComponent as ChevronLeft} from "../../images/chevron-left.svg";
import {request} from "../../util/request";


const KeywordRankingTable = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const totalPage = 6;
    const [keywords, setKeywords] = useState([]);
    const getKeywords = useCallback(() => {
        request.get("/api/v1/admin/search/ranking", {
            params: {
                page: currentPage
            }
        })
            .then(({status, data}) => {
                setKeywords([...data.keywords])
            })
    }, [currentPage, totalPage])

    const increasePage = useCallback(() => {
        if (currentPage < 5) {
            setCurrentPage(currentPage + 1);
        }
    }, [currentPage])
    const decreasePage = useCallback(() => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }, [currentPage])

    useEffect(() => {
        getKeywords()
    }, [currentPage])
    return (
        <div>
            <h2 className={styles.header}>검색 순위</h2>
            <table style={{width: '100%'}}>
                <thead>
                <tr className={styles.tableHeader}>
                    <th className={styles.headerColumn}>
                        순위
                    </th>
                    <th className={styles.headerColumn}>
                        검색어
                    </th>
                    <th className={styles.headerColumn}>
                        횟수
                    </th>
                </tr>
                </thead>
                {
                    keywords.map(({ranking, keyword, count}) => (
                        <tr className={styles.tableRow} key={ranking + keyword}>
                            <td className={styles.rowColumn}>
                                {ranking}위
                            </td>
                            <td className={`${styles.rowColumn} ${styles.keywordColumn}`}>
                                {keyword}
                            </td>
                            <td className={styles.rowColumn}>
                                {count.toLocaleString()}회
                            </td>
                        </tr>
                    ))
                }

            </table>
            <div className={styles.pagination}>
                <button
                    className={`${styles.chevron} ${currentPage == 0 ? styles.disable : ''}`}
                    onClick={() => decreasePage()}
                    disabled={currentPage < 1}
                >
                    <ChevronLeft/>
                </button>
                <div className={styles.page}>
                    <div className={styles.currentPage}>{currentPage + 1}</div>
                    <div>/</div>
                    <div>{totalPage}</div>
                </div>
                <button
                    className={`${styles.chevron} ${currentPage == 5 ? styles.disable : ''}`}
                    onClick={() => increasePage()}
                    disabled={currentPage > 5}
                >
                    <ChevronRight/>
                </button>
            </div>
        </div>
    );
};

export default KeywordRankingTable;