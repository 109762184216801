import {createContext, useState} from "react";
const now = new Date();
const ReviewDateContext = createContext({
    state: {
        startDate: new Date(now.setMonth(now.getMonth() - 3)),
        endDate: new Date()
    },
    action: {
        setStartDate: ()=>{},
        setEndDate: ()=>{}
    }
});

const ReviewDateProvider = ({children})=>{
    const now = new Date();
    const [startDate, setStartDate] = useState(new Date(now.setMonth(now.getMonth() - 3)));
    const [endDate, setEndDate] = useState(new Date());

    const value = {
        state: {startDate, endDate},
        action: {setStartDate, setEndDate}
    };

    return (
        <ReviewDateContext.Provider value={value}>{children}</ReviewDateContext.Provider>
    )
}

const {Consumer: ReviewDateConsumer} = ReviewDateContext;
export {ReviewDateProvider, ReviewDateConsumer};
export default ReviewDateContext;