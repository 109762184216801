import React from 'react';
import styles from '../../../styles/noticeAndFaqPage/notice/noticeTableRow.module.css'
import {Link} from "react-router-dom";

const NoticeTableRow = ({
                            id, createdAt, publisher, category, categoryId, title, startedAt, endedAt
                        }) => {
    return (
        <Link to={`/notice/editor?category=${categoryId !== null ? categoryId : 'event'}&id=${id}`}
              style={{textDecoration: 'none'}}>
            <tr className={styles.row}>
                <td className={styles.column}>
                    {createdAt}
                </td>
                <td className={styles.column}>
                    {publisher}
                </td>
                <td className={styles.column}>
                    {category}
                </td>
                <td className={styles.column}>
                    {title}
                </td>
                <td className={styles.column}>
                    {(startedAt != null) ? `${startedAt} ~ ${endedAt?endedAt:''}` : ''}
                </td>
            </tr>
        </Link>

    );
};

export default NoticeTableRow;