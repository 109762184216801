import '../styles/App.css';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import RoutesContainer from "./Router/RoutesContainer";
import RecoilNexus from "recoil-nexus";


function App() {
    return (
        <div className="App">
            <RecoilRoot>
                <RecoilNexus/>
                <BrowserRouter>
                    <RoutesContainer/>
                </BrowserRouter>
            </RecoilRoot>
        </div>
    );
}

export default App;
