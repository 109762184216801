import React, {useCallback, useEffect, useState} from 'react';
import '../../styles/lectureList/lectureListTableRow.css';
import {ReactComponent as ToggleOn} from "../../images/toggle-on.svg";
import {ReactComponent as ToggleOff} from "../../images/toggle-off.svg";
import {Link} from "react-router-dom";
import {request} from "../../util/request";


const LectureTableRow = ({
                             id, date, category, platform, title, instructor, reviewCount, displayed
                         }) => {
    const [isLoading, setIsLoading] = useState();
    const [isDisplayed, setIsDisplayed] = useState(displayed);
    const editToDisplayFalse = useCallback(() => {
        if (!isDisplayed) {
            return;
        }
        setIsDisplayed(false);
        request.patch(`/api/v1/admin/lecture/${id}/display/false`)
            .then(({status}) => {
                if (status != 200) {
                    setIsDisplayed(true);
                }
            })
            .catch(() => {
                setIsDisplayed(true);
            })
    }, [id, isDisplayed])
    const editToDisplayTrue = useCallback(() => {
        if (isDisplayed) {
            return;
        }
        setIsDisplayed(true);
        request.patch(`/api/v1/admin/lecture/${id}/display/true`)
            .then(({status}) => {
                if (status != 200) {
                    setIsDisplayed(false);
                }
            })
            .catch(() => {
                setIsDisplayed(false);
            })
    }, [id, isDisplayed])
    useEffect(() => {
        setIsDisplayed(displayed);
    }, [displayed])
    return (
        <div className={'lectureTableRow'}>
            <div className={'lectureTableRowColumn'}>
                {date}
            </div>
            <div className={'lectureTableRowColumn'}>
                {category}
            </div>
            <div className={'lectureTableRowColumn'}>
                {platform}
            </div>
            <div className={'lectureTableRowColumn'}>
                <Link to={`/lectures/${id}`} style={{textDecoration: 'none', color: '#67707A'}}>
                    {title}
                </Link>
            </div>
            <div className={'lectureTableRowColumn'}>
                {instructor}
            </div>
            <div className={'lectureTableRowColumn'}>
                {reviewCount}
            </div>
            <div className={'lectureTableRowColumn'}>
                <button className={'toggle'}
                        onClick={() => {
                            if (isDisplayed) {
                                editToDisplayFalse()
                            } else {
                                editToDisplayTrue()
                            }
                        }}
                >
                    {isDisplayed ? <ToggleOn/> : <ToggleOff/>}
                </button>
            </div>
        </div>
    );
};

export default LectureTableRow;