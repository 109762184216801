import React, {useCallback} from 'react';
import styled from "styled-components";
import Modal from "react-modal";
import {ReactComponent as XClose} from "../../images/x-close.svg";
import styles from '../../styles/search/alertModal.module.css'

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const AlertModal = ({ isOpen, setIsOpen}) => {
    const closeModal = useCallback(()=>{
        setIsOpen(false);
    }, [])
    return (
        <SModal
            style={{
                overlay: {
                    backgroundColor: "#00000066"
                }}}
            isOpen={isOpen}
        >
            <div
                className={styles.close}
                onClick={()=>closeModal()}>
                <XClose/>
            </div>
            <div className={styles.header}>10개 이상 추가할 수 없어요.</div>
            <div className={styles.body}>다른 검색어를 삭제하고 다시 추가해보세요.</div>
            <button
                 className={styles.yesButton}
                onClick={()=>closeModal()}>
            네</button>
        </SModal>
    );
};

export default AlertModal;