import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as Bell} from '../../images/bell.svg'
import styles from '../../styles/notification/notificationContainer.module.css'
import NotificationModal from "./NotificationModal";
import {set} from "immutable";
import {useRecoilState} from "recoil";
import {notificationsAtom} from "../../recoil/atom";


const NotificationContainer = () => {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom)
    const modalEl = useRef();
    const [isOpen, setOpen] = useState(false);
    const openModal = useCallback(() => {
        setOpen(true);
    }, [isOpen])

    return (
        <>
            <div className={styles.container}
                 onClick={() => openModal()}
            >
                <Bell/>
                <div className={styles.content}>
                    <div>알림</div>
                    <div className={styles.badge}>{notifications.length}</div>
                </div>
            </div>
            <div
                ref={modalEl}>
            { isOpen &&
                <NotificationModal
                    modalEl={modalEl}
                    setOpen={setOpen}
                    isOpen={isOpen}
                />
            }
            </div>

        </>

    );
};

export default NotificationContainer;