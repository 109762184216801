import React, {useCallback, useState} from 'react';
import '../../styles/common/selectBox.css'
import {ReactComponent as XCircle} from '../../images/x-circle.svg'
import {request} from "../../util/request";

const SelectBox = ({
                       setCheckedValue,
                       name,
                       id,
                       isEditorMode,
                       checkedValue,
                       setLoaded,
                       type
                   }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const deleteItem = useCallback(() => {
        setIsLoaded(true);
        if (type === 'category') {
            request.delete(`/api/v1/category/${id}`)
                .then(({status}) => {
                    if (status === 200) {
                        alert('카테고리 삭제 성공했습니다.');
                        setLoaded(false);

                    } else {
                        alert('카테고리 삭제 실패했습니다.');
                    }
                    setIsLoaded(false);
                })
                .catch((e) => {
                    console.log('delete category error:' + e)
                    setIsLoaded(false);

                })
        } else if (type === 'platform') {
            request.delete(`/api/v1/platform/${id}`)
                .then(({status}) => {
                    if (status === 200) {
                        alert('플랫폼 삭제 성공했습니다.')
                        setLoaded(false);

                    } else {
                        alert('플랫폼 삭제 실패했습니다.')
                    }
                    setIsLoaded(false);
                })
                .catch((e) => {
                    console.log('delete platform error:' + e)
                    setIsLoaded(false);

                })
        }else if(type === 'notice'){
            request.delete(`/api/v1/admin/notice/category/${id}`)
                .then(({status}) => {
                    if (status === 200) {
                        alert('공지 카테고리 삭제에 성공했습니다.')
                        setLoaded(false);

                    } else {
                        alert('공지 카테고리 삭제에 실패했습니다.')
                    }
                    setIsLoaded(false);
                })
                .catch((e) => {
                    console.log('delete notice category error:' + e)
                    setIsLoaded(false);

                })
        }else if(type === 'faq'){
            request.delete(`/api/v1/admin/faq/category/${id}`)
                .then(({status}) => {
                    if (status === 200) {
                        alert('faq 카테고리 삭제에 성공했습니다.')
                        setLoaded(false);

                    } else {
                        alert('faq 카테고리 삭제에 실패했습니다.')
                    }
                    setIsLoaded(false);
                })
                .catch((e) => {
                    console.log('delete faq category error:' + e)
                    setIsLoaded(false);
                })
        }

    }, [id, type])

    return (
        <div
            className={`selectBox ${isEditorMode ? 'selectBoxEditMode' : ''} ${checkedValue === id && !isEditorMode ? 'active' : ''}`}
            type={'checkbox'}
            onClick={() => {
                setCheckedValue(id);
            }
            }
        >
            <div>
                {name}
            </div>
            {
                isEditorMode &&
                <button
                    onClick={() => {
                        deleteItem();
                    }
                    }
                    className={'selectBoxXCircleSvg'}
                    disabled={isLoaded}
                >
                    <XCircle/>
                </button>
            }
        </div>
    );
};

export default SelectBox;