import React, {useEffect, useState} from 'react';
import SelectBoxContainer from "../../common/SelectBoxContainer";
import {request} from "../../../util/request";

const CategoryContainer = ({checkedValue, setCheckedValue, type}) => {
    const [isLoaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([])
    useEffect(() => {
        if (!isLoaded) {
            if (type === 'new') {
                request.get('/api/v1/admin/notice/category')
                    .then(({status, data}) => {
                        if (status !== 200) {
                            return;
                        }
                        setCategories([{name: '이벤트', id: 'event'}, ...data.categories])
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        setLoaded(true);
                    })
            } else if (type === 'editEvent') {
                setCategories([{name: '이벤트', id: 'event'}])
            } else if (type === 'editNotice') {
                request.get('/api/v1/admin/notice/category')
                    .then(({status, data}) => {
                        if (status !== 200) {
                            return;
                        }
                        setCategories([...data.categories])
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        setLoaded(true);
                    })
            }


        }
    }, [isLoaded, type])
    return (
        <div>
            <SelectBoxContainer
                name={'카테고리'}
                items={type === 'editEvent' ? [{name: '이벤트', id: 'event'}] : categories}
                setCheckedValue={setCheckedValue}
                checkedValue={checkedValue}
                setLoaded={setLoaded}
                selectBoxType={'notice'}
            />
            <div>
            </div>

        </div>
    );
};

export default CategoryContainer;