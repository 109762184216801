import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/search/recommendationKeyword.module.css'
import {ReactComponent as Plus} from "../../images/plus-button.svg";
import KeywordButton from "./KeywordButton";
import KeywordAddModal from "./KeywordAddModal";
import AlertModal from "./AlertModal";
import {request} from "../../util/request";

const RecommendationKeyword = () => {
    const [keywords, setKeywords] = useState([]);
    const [loadState, setLoadState] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isAlertModalOpen, setAlertModalOpen] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    useEffect(() => {
        if (loadState) return;
        request.get('/api/v1/admin/search/recommendation')
            .then(({status, data}) => {
                setKeywords([...data.keywords])
                setLoadState(true);
                setTotalCount(data.totalCount);
            })
    }, [loadState])
    const openModal = useCallback(() => {
        if (totalCount > 9) {
            setAlertModalOpen(true);
        } else {
            setAddModalOpen(true);
        }
    }, [totalCount])
    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    추천 검색어
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.keywordContainer}>
                        {keywords.map(({keyword, id}) => (
                            <KeywordButton
                                key={id}
                                keyword={keyword}
                                id={id}
                                setLoadState={setLoadState}
                            />
                        ))}
                    </div>
                    <button
                        className={styles.addContainer}
                        onClick={() => openModal()}
                    >
                        <div className={styles.plusSvg}>
                            <Plus/>
                        </div>
                        <div>추가하기</div>
                    </button>
                </div>
            </div>
            <KeywordAddModal
                isOpen={isAddModalOpen}
                setIsOpen={setAddModalOpen}
                setLoadState={setLoadState}
            />
            <AlertModal
                isOpen={isAlertModalOpen}
                setIsOpen={setAlertModalOpen}
            />
        </>

    );
};

export default RecommendationKeyword;