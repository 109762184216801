import React, {useCallback, useState} from 'react';
import Header from "../common/Header";
import {Link} from "react-router-dom";
import styles from '../../styles/noticeAndFaqPage/noticeAndFaq.module.css'
import NoticeList from "./notice/NoticeList";
import FaqList from "./faq/FaqList";

const NoticeAndFaqPage = () => {
    const [currentPage, setCurrentPage] = useState('notice')
    const onClickNotice = useCallback(() => {
        return () => setCurrentPage('notice');
    }, [])
    const onClickFaq = useCallback(() => {
        return () => setCurrentPage('faq');
    }, [])
    return (
        <div>
            <Header name={'공지 및 FAQ'}/>
            <div className={styles.linkContainer}>
                <div className={styles.link}>
                    <div className={currentPage === 'notice' ? styles.active : ''} onClick={onClickNotice()}>공지</div>
                    <div className={currentPage === 'faq' ? styles.active : ''} onClick={onClickFaq()}>FAQ</div>
                </div>
                {
                    currentPage === 'notice' ?
                        <Link to={'/notice/editor'} style={{textDecoration: "none"}}>
                            <div className={styles.registerBtn}>공지 등록하기</div>
                        </Link>
                        :
                        <Link to={'/faq/editor'} style={{textDecoration: "none"}}>
                            <div className={styles.registerBtn}>FAQ 등록하기</div>
                        </Link>
                }
            </div>
            <div>
                {
                    currentPage === 'notice' ?
                        <NoticeList/>
                        :
                        <FaqList/>
                }
            </div>

        </div>
    );
};

export default NoticeAndFaqPage;