import styles from '../../../styles/notice/noticeEditor/noticeEditor.module.css';
import React, {useCallback, useEffect, useState} from 'react';
import FormInputText from "../../common/FormInputText";
import qs from "qs";
import {useLocation, useNavigate} from "react-router-dom";
import {request} from "../../../util/request";

const NoticeEditor = ({categoryId, setCategory}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [id, setId] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let query = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setId(query.id);
    }, [location])

    useEffect(() => {
        if (id != null) {
            setLoading(true);
            request.get('/api/v1/notice/' + id)
                .then(({status, data}) => {
                    if (status === 200) {
                        setTitle(data.title)
                        setContent(data.body)
                        setCategory(data.categoryId)
                    }
                })
                .catch(() => {
                })
                .finally(() => setLoading(false))
        }
    }, [id])

    const postNotice = useCallback(() => {
        if(!(categoryId && title && content)) return;
        setLoading(true)
        request.post('/api/v1/admin/notice', {
            categoryId,
            title,
            body: content
        })
            .then(({status, data}) => {
                if (status === 201) {
                    alert('공지 등록 성공!')
                    navigate('/notice-faq');
                    return;
                }
                alert('공지 등록 실패했습니다!')

            })
            .catch(() => {
                alert('공지 등록 실패했습니다!')
            })
            .finally(() => {
                setLoading(false);
            })
    }, [title, content, categoryId])

    const deleteNotice = useCallback(()=>{
        setLoading(true);
        request.delete('/api/v1/admin/notice/'+id)
            .then(({status})=>{
                if(status === 200){
                    alert("공지 삭제 성공!")
                    navigate('/notice-faq');
                }else{
                    alert("공지 삭제 실패!")
                }
            })
            .catch(()=>{
                alert("공지 삭제 실패!")
            })
            .finally(()=>setLoading(false))
    }, [id])

    const updateNotice = useCallback(()=>{
        setLoading(true)
        request.put('/api/v1/admin/notice/' + id, {
            categoryId,
            title,
            body: content
        })
            .then(({status})=>{
                if(status === 200) {
                    alert("공지 수정 성공!")
                    navigate('/notice-faq')
                }else{
                    alert("공지 수정 실패!")
                }
            })
            .catch(()=>{
                alert("공지 수정 실패!")
            })
            .finally(()=>{setLoading(false)})
    },[title, content, categoryId])

    return (
        <div
            className={styles.container}
        >
            <FormInputText
                name={'제목'}
                placeholder={'공지 제목을 입력해주세요.'}
                type={'text'}
                setValue={setTitle}
                value={title}
            />
            <div>
                <div className={'lectureRequestDetailContainer'}>
                    <div className={'lectureRequestDetailTitle'}>내용</div>
                    <div className={'lectureRequestDetail'}>
                        <textarea
                            className={'lectureRequestDetailText'}
                            placeholder={'공지 내용'}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        ></textarea>
                    </div>

                </div>
            </div>
            <div className={`${styles.buttonContainer}`}>
                {
                    !id ?
                        <>
                            <button
                                className={styles.exitButton}
                                disabled={isLoading}
                                onClick={() => navigate('/notice-faq')}
                            >취소하기
                            </button>
                            <button
                                className={`${styles.registerButton} ${!(title && content && categoryId) ? '' : styles.active}`}
                                onClick={() => {
                                    postNotice()
                                }}
                                disabled={isLoading || !(title && content && categoryId)}
                            >등록하기
                            </button>
                        </>
                        :
                        <>
                            <button
                                className={styles.exitButton}
                                disabled={isLoading}
                                onClick={() => {
                                    deleteNotice()
                                }}
                            >삭제하기
                            </button>
                            <button
                                className={`${styles.registerButton} ${!(title && content && categoryId) ? '' : styles.active}`}
                                onClick={() => {
                                    updateNotice()
                                }}
                                disabled={isLoading || !(title && content && categoryId)}
                            >수정하기
                            </button>
                        </>
                }


            </div>
        </div>

    );
};

export default NoticeEditor;