import {atom} from "recoil";
const {recoilPersist} = require('recoil-persist');

const {persistAtom} = recoilPersist();
export const accessTokenAtom = atom({
    key: "accessTokenAtom",
    default: null,
    effects_UNSTABLE:[persistAtom]
})

export const refreshTokenAtom = atom({
    key: "refreshTokenAtom",
    default: null,
    effects_UNSTABLE:[persistAtom]
})

export const notificationsAtom = atom(({
    key: "notificationsAtom",
    default: []
}))

export const lectureRequestCntAtom = atom(({
    key: "lectureRequestCntAtom",
    default: 0
}))