import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import styles from "../../styles/common/headerWithBack.module.css";
import styled from "styled-components";
import Modal from "react-modal";
import {ReactComponent as Close} from "../../images/x-close.svg";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const ExitButton = () => {
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <button
                style={{
                    'width': '160px',
                    'height': '56px',
                    'background': '#EBEDF0',
                    borderRadius: '12px',
                    'border': "none",
                    'color': '#878F99',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '20px',
                    'cursor': 'pointer'
                }}
                onClick={() => {
                    setModalOpen(true);
                }}>
                취소하기
            </button>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <div className={styles.modal}>
                    <div
                        className={styles.closeBtn}
                        onClick={() => setModalOpen(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className={styles.header}>앗...작성했던 내용이 사라져요.</div>
                    <div className={styles.body}>취소하면 작성했던 내용이 사라져요. 정말로 취소하시나요?</div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.modalExitButton}
                            onClick={() => setModalOpen(false)}>닫기
                        </button>
                        <button
                            onClick={() => {
                                navigate(-1)
                            }}
                            className={styles.modalDeleteButton}
                        >취소하기
                        </button>
                    </div>
                </div>
            </SModal>
        </>

    );
};

export default ExitButton;