import React, {useEffect, useState} from 'react';
import '../../styles/lectureRequest/lectureRequestContainer.css';
import HeaderWithBack from "../common/HeaderWithBack";
import CategoryTree from "../common/CategoryTree";
import FormInputText from "../common/FormInputText";
import SelectBoxContainer from "../common/SelectBoxContainer";
import {useParams} from "react-router-dom";
import RegisterButton from "./RegisterButton";
import DeleteButton from "./DeleteButton";
import RetouchButton from "./RetouchButton";
import ExitButton from "./ExitButton";
import RegisterLectureRequestButton from "./RegisterLectureRequestButton";
import ReviewListContainer from "../reviewList/ReviewListContainer";
import {request} from "../../util/request";

const LectureRequestContainer = ({
                                     type
                                 }) => {
    const [lectureLink, setLectureLink] = useState('');
    const [title, setTitle] = useState('');
    const [instructor, setInstructor] = useState('');
    const [price, setPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [discountRate, setDiscountRate] = useState(0.0);
    const [platformId, setPlatformId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [platforms, setPlatforms] = useState([]);
    const [categories, setCategories] = useState([])
    const [detail, setDetail] = useState('');
    const [collectionPeriod, setCollectionPeriod] = useState('');
    const [isPlatformLoaded, setIsPlatformLoaded] = useState(false);
    const [isCategoryLoaded, setIsCategoryLoaded] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        const numberPrice = Number(String(price).replaceAll(",", ""));
        const numberDiscountRate = Number(String(discountPrice).replaceAll(",", ""));

        if (numberPrice === 0 || numberDiscountRate === 0) {
            setDiscountRate(0);
            return;
        }
        const rate = (numberPrice - numberDiscountRate) / numberPrice * 100;
        setDiscountRate(Math.round(rate * 10) / 10.0);
    }, [price, discountPrice])

    useEffect(() => {
        setPrice((prevState) => {
            const removedCommaValue = Number(String(prevState).replaceAll(",", ""));
            if (!Number.isInteger(removedCommaValue)) {
                return 0;
            }
            return removedCommaValue.toLocaleString()
        });
    }, [price])

    useEffect(() => {
        setDiscountPrice((prevState) => {
            const removedCommaValue = Number(String(prevState).replaceAll(",", ""));
            if (!Number.isInteger(removedCommaValue)) {
                return 0;
            }
            return removedCommaValue.toLocaleString();
        });

    }, [discountPrice])

    useEffect(() => {
        if (!isCategoryLoaded) {
            (async () => {
                const response = await request.get('/api/v1/category')
                if (response.status === 200) {
                    setCategories([...response.data.categories])
                }
                setIsCategoryLoaded(true);
            })();
        }
        if (!isPlatformLoaded) {
            (async () => {
                const response = await request.get('/api/v1/admin/platform')
                if (response.status === 200) {
                    setPlatforms([...response.data.platforms])
                }
                setIsPlatformLoaded(true);
            })();
        }


    }, [isCategoryLoaded, isPlatformLoaded])

    useEffect(() => {
        if (type === 'userRequest') {
            (async () => {
                const response = await request.get(`/api/v1/admin/lecture/request/${id}`)
                if (response.status === 200) {
                    setLectureLink(response.data.link);
                    setTitle(response.data.title);
                }
            })();
        } else if (type === 'editLecture') {
            (async () => {
                const {status, data} = await request.get(`/api/v1/admin/lecture/${id}`)
                if (status === 200) {
                    const {
                        name,
                        instructor,
                        price,
                        discountRate,
                        discountPrice,
                        collectionPeriod,
                        introduction,
                        lectureLink,
                        platformId,
                        categoryId
                    } = data;
                    setTitle(name);
                    setInstructor(instructor)
                    setPlatformId(platformId)
                    setDiscountRate(discountRate)
                    setDiscountPrice(discountPrice)
                    setPrice(price)
                    setCollectionPeriod(collectionPeriod)
                    setDetail(introduction)
                    setLectureLink(lectureLink)
                    setCategoryId(categoryId)
                }
            })();
        }

    }, [id])


    return (
        <div className={'lectureRequestContainer'}>
            {
                type === 'userRequest' ?
                    <>
                        <HeaderWithBack name={'등록하기'}/>
                        <CategoryTree categories={['신청 목록', '등록하기']}/>
                    </>
                    :
                    type === 'adminRequest' ?
                        <>
                            <HeaderWithBack name={'강의 등록하기'}/>
                            <CategoryTree categories={['강의 목록', '강의 등록하기']}/>
                        </>
                        :
                        <>
                            <HeaderWithBack name={'강의 내용'}/>
                            <CategoryTree categories={['강의 목록', '강의 내용']}/>
                        </>

            }
            <div className={'lectureRequestForm'}>
                <FormInputText name={'강의 링크'} placeholder={'강의 링크를 입력해주세요.'} type={'text'} value={lectureLink}
                               setValue={setLectureLink}/>
                <div className={'lectureRequestPriceGrid'}>
                    <FormInputText name={'강의 가격'} placeholder={'0'} unit={'원'} type={'text'} value={price}
                                   setValue={setPrice}
                                   maxLength={20}
                    />
                    <FormInputText name={'할인 가격'} placeholder={'0'} unit={'원'} type={'text'} value={discountPrice}
                                   maxLength={20}

                                   setValue={setDiscountPrice}/>
                    <div className={'discountRateContainer'}>
                        <div className={'discountRateTitle'}>할인률</div>
                        <div className={'discountRate'}>
                            <div>{discountRate}%</div>
                        </div>
                    </div>
                    <FormInputText name={'소장기간'} placeholder={'강의 구매 시 소장 기간을 입력해주세요.'} type={'text'}
                                   value={collectionPeriod}
                                   setValue={setCollectionPeriod}/>
                </div>

                <SelectBoxContainer
                    name={'강의 플랫폼'}
                    items={platforms}
                    setCheckedValue={setPlatformId}
                    checkedValue={platformId}
                    setLoaded={setIsPlatformLoaded}
                    selectBoxType={'platform'}
                />
                <SelectBoxContainer
                    name={'카테고리'}
                    items={categories}
                    setCheckedValue={setCategoryId}
                    checkedValue={categoryId}
                    setLoaded={setIsCategoryLoaded}
                    selectBoxType={'category'}
                />
                <FormInputText name={'강의명'} placeholder={'강의명을 입력해주세요.'} type={'text'} value={title}
                               setValue={setTitle}/>
                <FormInputText name={'튜터'} placeholder={'누구한테 강의를 들었나요? e.g.스무'} type={'text'} value={instructor}
                               setValue={setInstructor}/>

                <div className={'lectureRequestDetailContainer'}>
                    <div className={'lectureRequestDetailTitle'}>강의 소개</div>
                    <div className={'lectureRequestDetail'}>
                        <textarea
                            className={'lectureRequestDetailText'}
                            placeholder={'강의 요약'}
                            value={detail}
                            onChange={(e) => setDetail(e.target.value)}
                        ></textarea>
                    </div>

                </div>
            </div>
            <div className={'lectureRequestButtonContainer'}>
                {
                    (type === 'adminRequest') ?
                        <>
                            <ExitButton></ExitButton>
                            <RegisterButton
                                name={title}
                                detail={detail}
                                instructor={instructor}
                                link={lectureLink}
                                price={price}
                                discountRate={discountRate}
                                discountPrice={discountPrice}
                                platformId={platformId}
                                categoryId={categoryId}
                                collectionPeriod={collectionPeriod}
                            ></RegisterButton>
                        </>

                        :
                        (type === 'userRequest') ?
                            <>
                                <ExitButton></ExitButton>
                                <RegisterLectureRequestButton
                                    name={title}
                                    detail={detail}
                                    instructor={instructor}
                                    lectureRequestId={id}
                                    link={lectureLink}
                                    price={price}
                                    discountRate={discountRate}
                                    discountPrice={discountPrice}
                                    platformId={platformId}
                                    categoryId={categoryId}
                                    collectionPeriod={collectionPeriod}
                                ></RegisterLectureRequestButton>
                            </>
                            :
                            <>
                                <DeleteButton
                                    lectureId={id}
                                ></DeleteButton>
                                <RetouchButton
                                    lectureId={id}
                                    name={title}
                                    detail={detail}
                                    instructor={instructor}
                                    link={lectureLink}
                                    price={price}
                                    discountRate={discountRate}
                                    discountPrice={discountPrice}
                                    platformId={platformId}
                                    categoryId={categoryId}
                                    collectionPeriod={collectionPeriod}
                                ></RetouchButton>
                            </>
                }
            </div>
            {type === 'editLecture' &&
                <ReviewListContainer
                    lectureId={id}
                />
            }
        </div>
    );
};

export default LectureRequestContainer;