import React, {useCallback, useEffect, useState} from 'react';
import {ReactComponent as Search} from "../../images/search.svg";
import styles from '../../styles/userPage/userInfoTable.module.css'
import UserInfoTableRow from "./UserInfoTableRow";
import {request} from "../../util/request";

const UserInfoTable = () => {
    const [totalCount, setTotalCount] = useState(0);
    const [query, setQuery] = useState('');
    const [sort, setSort] = useState('latest');
    const [users, setUsers] = useState([]);

    const search = useCallback(() => {
        request.get("/api/v1/admin/user/search", {params: {q: query, sort}})
            .then(({status, data}) => {
                if (status === 200) {
                    setUsers([...data.users]);
                    setTotalCount(data.totalCount);
                }
            })
    }, [sort, query])
    const enterInput = useCallback((e) => {
        if (e.key === 'Enter') {
            search()
        }
    }, [search])

    const changeSort = useCallback((e) => {
        setSort(e.target.value);
    }, [])

    useEffect(() => {
        search()
    }, [sort])

    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.inputContainer}>
                        <input
                            className={styles.inputSearch}
                            placeholder={'포지션, 년차, 닉네임을 검색하세요.'}
                            value={query}
                            onChange={event => setQuery(event.target.value)}
                            onKeyDown={enterInput}
                        />
                        <div className={styles.searchSvg}>
                            <Search/>
                        </div>
                    </div>
                    <div className={styles.totalCountContainer}>
                        총
                        <span className={styles.totalCount}>{' ' + totalCount.toLocaleString() + ' '}</span>
                        명
                    </div>
                </div>
                <select
                    className={styles.sort}
                    onChange={changeSort}
                >
                    <option value={'latest'}>최신순</option>
                    <option value={'oldest'}>등록순</option>
                    <option value={'review-desc'}>리뷰수</option>
                    <option value={'recommendation-desc'}>좋아요수</option>
                    <option value={'report-desc'}>신고수</option>
                </select>
            </div>
            <table className={styles.table}>
                <thead>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.tableHeaderColumn}>
                        닉네임
                    </th>

                    <th className={styles.tableHeaderColumn}>
                        연동 계정
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        포지션
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        년차
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        리뷰수/좋아요수<br/>
                        신고수
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        마지막 로그인<br/>
                        가입일
                    </th>
                    <th className={styles.tableHeaderColumn}>
                        마케팅 동의 여부<br/>
                        알림 푸시 여부
                    </th>
                </tr>
                </thead>
                <tbody>
                {users.map(({
                                userId,
                                nickname,
                                oauthProvider,
                                position,
                                year,
                                reviewCount,
                                recommendationCount,
                                reportCount,
                                lastLoginDate,
                                registerDate,
                                marketingAgreed
                            }) => (
                    <UserInfoTableRow
                        userId={userId}
                        nickname={nickname}
                        oauthProvider={oauthProvider}
                        position={position}
                        year={year}
                        reviewCount={reviewCount}
                        recommendationCount={recommendationCount}
                        reportCount={reportCount}
                        lastLoginDate={lastLoginDate}
                        registerDate={registerDate}
                        marketingAgreed={marketingAgreed}
                    />
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserInfoTable;