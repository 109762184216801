import React, {useCallback, useEffect, useState} from 'react';
import {Doughnut, Line} from "react-chartjs-2";
import styles from '../../styles/userPage/positionGraph.module.css'
import {request} from "../../util/request";

const PositionGraph = () => {
    const [graphData, setGraphData] = useState({
        labels: [],
        data: []
    });
    const chartOptions = {
        plugins: {
            legend: {
                position: 'right',
                layout: {
                    padding: {
                        left: 120,
                        right:120,
                    }
                }
            }
        }
    };
    const getGraphData = useCallback(() => {
        request.get( '/api/v1/admin/user/position-graph')
            .then(({status, data}) => {
                const {positions} = data;
                if (status === 200) {
                    setGraphData((prevState) => ({
                        ...prevState,
                        labels: positions.map((pos) => pos.x),
                        data: positions.map((pos) => pos.y)
                    }));
                }
            })
    }, [])
    useEffect(() => {
        getGraphData();
    }, [])
    return (
        <div>
            <div className={styles.graphContainer}>
                <Doughnut
                    data={{
                        labels: graphData.labels,
                        datasets: [{
                            label: '사용자',
                            data: graphData.data,
                            backgroundColor: ['blue', 'yellow', 'red', 'green', 'gray', 'purple', 'orange']
                        }]
                    }}
                    options={{
                        plugins: {
                            legend: {
                                position: 'right',
                            },
                        }
                    }}
                />
            </div>

        </div>
    );
};

export default PositionGraph;