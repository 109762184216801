import React from 'react';
import DashboardContainer from "./dashboard/DashboardContainer";
import LectureRequestAlarmContainer from "./lectureRequestAlarm/LectureRequestAlarmContainer";

const HomeContainer = () => {
    return (
        <>
            <DashboardContainer/>
            <LectureRequestAlarmContainer/>
        </>
    );
};

export default HomeContainer;