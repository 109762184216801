import React, {useEffect, useState} from 'react';
import '../../styles/sideBar/menuButton.css'
import {useLocation, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {lectureRequestCntAtom} from "../../recoil/atom";

const MenuButton = (
    {svgComponent, menuName, to}
) => {
    const location = useLocation();
    const lectureRequestCnt = useRecoilValue(lectureRequestCntAtom);

    const [color, setColor] = useState('#A9B0B8');
    useEffect(()=>{
        if(to === location.pathname){
            setColor('#FFFFFF');
        }else{
            setColor('#A9B0B8');
        }
    }, [location.pathname])

    return (
        <div className={"menuButton"}>
            <div style={{fill: color}}>
                    {svgComponent}
            </div>
            <div className={"menuContent"}>
                <div style={{color}} className={'menuName'}>{menuName}</div>
                {menuName === "신청 목록" && <div className={"menuBadge"}>{lectureRequestCnt}</div>}
            </div>
        </div>
    );
};

export default MenuButton;