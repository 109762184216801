import React, {useCallback, useEffect, useState} from 'react';
import Header from "../common/Header";
import '../../styles/lectureRequestList/lectureRequestListContainer.css'
import {ReactComponent as SearchIcon} from "../../images/search-md.svg";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";

import {Link} from "react-router-dom";
import LectureRequestTableBody from "./LectureRequestTableBody";
import {request} from "../../util/request";
import {useSetRecoilState} from "recoil";
import {lectureRequestCntAtom} from "../../recoil/atom";

const LectureRequestListContainer = () => {
    const setLectureRequestCnt = useSetRecoilState(lectureRequestCntAtom);
    const [totalRequest, setTotalRequest] = useState(0);
    const [items, setItems] = useState([]);
    const [isLoadedContent, setIsLoadedContent] = useState(false);
    const [isDesc, setIsDesc] = useState(null);
    const [query, setQuery] = useState('');

    const onClickDesc = useCallback(() => {
        setItems([...items.sort((a, b) => {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            if (a.date === b.date) return 0;
        })]);
        setIsDesc(true);
    }, [items])

    const onClickAsc = useCallback(() => {
        setItems([...items.sort((a, b) => a.date.toLowerCase() < b.date.toLowerCase() ? -1 : 1)])
        setIsDesc(false);
    }, [items])

    const search = useCallback(() => {
        setIsDesc(null);
        request.get(`/api/v1/admin/lecture/request/search?q=${query}`)
            .then(({status, data}) => {
                if (status == 200) {
                    setItems([...data.lectureRequests]);
                    setTotalRequest(data.totalCount);
                }
            })

    }, [query]);

    useEffect(() => {
        if (isLoadedContent == false) {
            request.get('/api/v1/admin/lecture/request/all')
                .then(({data, status}) => {
                    if (status == 200) {
                        const {lectureRequests, totalCount} = data;
                        setItems([...lectureRequests]);
                        setTotalRequest(totalCount)
                        setIsLoadedContent(true);
                        setLectureRequestCnt(totalCount)
                    }
                });
        }

    }, [isLoadedContent])
    return (
        <div
            className={'lectureRequestListContainer'}
        >
            <Header name={'신청 목록'}></Header>
            <div className={'lectureRequestTopContainer'}>
                <div
                    className={'lectureRequestSearchContainer'}
                >
                    <div className={'lectureRequestSearchInputContainer'}>
                        <input
                            className={'lectureRequestSearchInput'}
                            placeholder={'키워드를 검색하세요.'}
                            onKeyUp={(e) => {
                                if (e.key == 'Enter') {
                                    search();
                                }
                            }}
                            onChange={
                                (e) => {
                                    setQuery(e.target.value);
                                }
                            }
                        />
                        <div className={'searchSvg'}
                             onClick={() => {
                                 search();
                             }}
                        >
                            <SearchIcon/>
                        </div>
                    </div>
                    <div className={'lectureRequestTotalContainer'}>
                        총{' '}
                        <span className={'lectureRequestTotal'}>
                            {totalRequest}
                        </span>
                        {' '}강의
                    </div>
                </div>
                <Link to={'/lectures/applications/new'} style={{textDecoration: 'none'}}>
                    <div className={'registerNewLectureButton'}>

                        강의 등록하기
                    </div>
                </Link>
            </div>
            <div className={'lectureRequestTableHeader'}>
                <div className={'tableHeaderColumn'}>
                    <div>
                        날짜
                    </div>
                    <div className={'lectureRequestTableDateSort'}>
                        <div className={isDesc === null ? '' : !isDesc ? 'active' : ''}>
                            <ChevronUp
                                onClick={() => {
                                    onClickAsc()
                                }}
                            ></ChevronUp>
                        </div>
                        <div className={isDesc === null ? '' : isDesc ? 'active' : ''}>
                            <ChevronDown
                                onClick={() => {
                                    onClickDesc()
                                }}
                            ></ChevronDown>
                        </div>
                    </div>
                </div>
                <div className={'tableHeaderColumn'}>
                    신청인
                </div>
                <div className={'tableHeaderColumn'}>
                    링크명
                </div>
            </div>
            {items.map((item) => (
                <LectureRequestTableBody
                    key={item.id}
                    date={item.date}
                    user={item.nickname}
                    title={item.title}
                    link={item.link}
                    id={item.id}
                    setIsLoadedContent={setIsLoadedContent}
                />
            ))}

        </div>
    );
};

export default LectureRequestListContainer;