import React from 'react';
import '../../styles/common/categoryTree.css';
const CategoryTree = ({
    categories
                      }) => {
    return (
        <div className={'categoryTreeContainer'}>
            {categories.map((category)=>(
                <div key={category} className={'categoryName'}>{category}</div>
            )).reduce((prev, curr) => [prev, <div key={prev} className={'leftChevron'}>></div>, curr])}
        </div>
    );
};

export default CategoryTree;