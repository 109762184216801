import React, {useCallback, useEffect, useState} from 'react';
import {ReactComponent as Editor} from "../../images/editor.svg";
import SelectBox from "./SelectBox";
import '../../styles/common/platformSelectContainer.css'
import {ReactComponent as Check} from '../../images/check.svg'
import {ReactComponent as Plus} from '../../images/plus-button.svg'
import Modal from 'react-modal';
import styled from "styled-components";
import PlatformModal from "../LectureRequest/PlatformModal";
import CategoryModal from "../LectureRequest/CategoryModal";
import NoticeCategoryModal from "../notice/category/NoticeCategoryModal";
import FaqCategoryModal from "../faq/FAQCategoryModal";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const SelectBoxContainer = ({
                                name,
                                items,
                                setCheckedValue,
                                checkedValue,
                                setLoaded,
                                selectBoxType,
                            }) => {
    const [isEditorMode, setEditorMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClickEditButton = useCallback(() => {
        setEditorMode(true);
    }, []);
    const bg = {
        overlay: {
            background: '#EBEDF0'
        }
    };


    return (
        <>
            <div className={'selectContainer'}>
                <div className={'selectHeader'}>
                    <div className={'selectName'}>{name}</div>
                    {isEditorMode ?
                        <div
                            className={'selectCheckButton'}
                            onClick={() => {
                                setEditorMode(false);
                            }
                            }
                        >
                            <div className={'selectCheckSvg'}>
                                <Check/>
                            </div>
                            <div>확인</div>
                        </div>
                        :
                        <div className={'selectEditor'} onClick={() => onClickEditButton()}>
                            <div className={'selectEditorSvg'}>
                                <Editor/>
                            </div>
                            <div>편집</div>
                        </div>
                    }

                </div>
                <div className={'selectBoxContainer'}>
                    {items.map((item) => (
                        <SelectBox
                            key={item.id}
                            name={item.name}
                            id={item.id}
                            setCheckedValue={setCheckedValue}
                            checkedValue={checkedValue}
                            isEditorMode={isEditorMode}
                            setLoaded={setLoaded}
                            type={selectBoxType}
                        />
                    ))}
                    {isEditorMode &&
                        <div className={'plusButtonContainer'}
                             onClick={() => {
                                 setIsModalOpen(true);
                             }}
                        >
                            <div className={'selectPlusButtonSvg'}>
                                <Plus width={'24px'} height={'24px'}/>
                            </div>
                            <div>추가하기</div>
                        </div>
                    }
                </div>
            </div>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isModalOpen}
                ariaHideApp={false}
                styles={bg}
                onRequestClose={() => setIsModalOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                {
                    selectBoxType === 'platform' ?
                        <PlatformModal
                            setIsOpenModal={setIsModalOpen}
                            setLoaded={setLoaded}
                        />
                        :
                        selectBoxType === 'category' ?
                            <CategoryModal
                                setIsOpenModal={setIsModalOpen}
                                setLoaded={setLoaded}
                            /> :
                            selectBoxType === 'notice' ?
                                <NoticeCategoryModal
                                    setIsOpenModal={setIsModalOpen}
                                    setLoaded={setLoaded}
                                />
                                :
                                <FaqCategoryModal
                                    setIsOpenModal={setIsModalOpen}
                                    setLoaded={setLoaded}
                                />

                }
            </SModal>
        </>

    );
};

export default SelectBoxContainer;