import React, {useEffect, useState} from 'react';
import Header from "../../common/Header";
import {Link} from "react-router-dom";
import LectureRequestCard from "./LectureRequestCard";
import '../../../styles/home/lectureRequestAlarm/lectureRequestAlarmContainer.css'
import {API_URL, request} from "../../../util/request";

const LectureRequestAlarmContainer = () => {
    const [lectureRequests, setLectureRequests] = useState([]);
    useEffect(()=>{
        (async ()=>{
            const {data, status} = await request.get('/api/v1/admin/lecture/request', {
                params:{page: 0, size: 4}
            });
            if(status!==200){
                return;
            }
            setLectureRequests((prevState)=> [...data.lectureRequests]);
        })();
    }, [])
    return (<div className={'LectureRequestAlarmContainer'}>
            <div className={"lectureRequestAlarmHeader"}>
                <Header name={'강의 신청 알림'}/>
                <Link to={'/lectures/applications'} style={{textDecoration: "none"}}>
                    <div className={'lectureRequestMore'}>
                        더보기
                    </div>
                </Link>

            </div>
            <div className={'lectureRequestCardContainer'}>
                {lectureRequests.map(({nickname,lectureRequestId, date})=>(
                    <LectureRequestCard
                        key={lectureRequestId}
                        nickName={nickname}
                        lectureId={lectureRequestId}
                        date={date}
                    />
                ))}
            </div>
        </div>);
};

export default LectureRequestAlarmContainer;