import React, {useState} from 'react';
import styles from '../../styles/userPage/userInfoTableRow.module.css'
import {ReactComponent as Apple} from "../../images/apple.svg";
import {ReactComponent as Kakao} from "../../images/kakao.svg";
import {ReactComponent as Google} from "../../images/google.svg";
import AccessHistoryModal from "./AccessHistoryModal";

const UserInfoTableRow = ({
                              userId,
                              nickname,
                              oauthProvider,
                              position,
                              year,
                              reviewCount,
                              recommendationCount,
                              reportCount,
                              lastLoginDate,
                              registerDate,
                              marketingAgreed
                          }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <tr className={styles.row}>
                <td className={styles.column}>
                    {nickname}
                </td>
                <td className={styles.column}>
                    <div className={styles.oauthContainer}>
                        <div className={styles.oauthSvg}>
                            {oauthProvider === '애플' ? <Apple/> : oauthProvider === '카카오' ? <Kakao/> : <Google/>}
                        </div>
                        <div>
                            {oauthProvider}
                        </div>
                    </div>

                </td>
                <td className={styles.column}>
                    {position}
                </td>
                <td className={styles.column}>
                    {year}
                </td>
                <td className={`${styles.column}`}>
                    <div className={styles.countContainer}>
                        <div className={styles.count}>
                            <div>
                                {reviewCount}/
                                {recommendationCount}
                            </div>

                            {reviewCount >= 10 && <div>🎖</div>}
                        </div>
                        {reportCount > 0 &&
                            <div className={styles.reportCount}>
                                <div>
                                    {reportCount}
                                </div>
                                {reportCount >= 10 &&
                                    <div>👿</div>
                                }
                            </div>

                        }
                    </div>

                </td>
                <td className={styles.column}>
                    <div className={styles.dateContainer}
                        onClick={()=>setModalOpen(true)}
                    >
                        {lastLoginDate && <div className={styles.date}>{lastLoginDate}</div>}
                        {registerDate && <div className={styles.date}>{registerDate}</div>}
                    </div>
                </td>
                <td className={styles.column}>
                    <div className={styles.marketingBtnContainer}>
                        <div className={`${styles.marketingBtn} ${marketingAgreed ? styles.active : ''}`}>
                            ON
                        </div>
                        <div className={`${styles.marketingBtn} ${!marketingAgreed ? styles.active : ''}`}>
                            OFF
                        </div>
                    </div>

                </td>
            </tr>
            <AccessHistoryModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
                userId={userId}
                />
        </>
    );
};

export default UserInfoTableRow;