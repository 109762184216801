import React, {useCallback, useState} from 'react';
import styles from "../../styles/search/keywordButton.module.css";
import {ReactComponent as Close} from "../../images/x-circle.svg";
import {request} from "../../util/request";


const KeywordButton = ({
                           keyword, id, setLoadState
                       }) => {
    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const deleteKeyword = useCallback(() => {
        if (isLoading) return;
        setLoading(true);
        request.delete(`/api/v1/admin/search/recommendation/${id}`)
            .then(() => {
                setLoadState(false);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [isLoading])
    return (
        <div className={`${styles.keyword} ${isEditMode ? styles.editMode : ''}`}
             onMouseOver={() => setEditMode(true)}
             onMouseOut={() => setEditMode(false)}
        >
            <div
            >{keyword}</div>
            {isEditMode &&
                <button
                    className={styles.deleteBtn}
                    onClick={() => deleteKeyword()}
                    disabled={isLoading}
                >
                    <Close/>
                </button>}
        </div>
    );
};

export default KeywordButton;