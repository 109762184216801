import React from 'react';
import Header from "../common/Header";
import PositionGraph from "./PositionGraph";
import UserInfoTable from "./UserInfoTable";

const UserPageContainer = () => {
    return (
        <div>
            <Header name={'사용자'}/>
            <PositionGraph/>
            <UserInfoTable/>
        </div>
    );
};

export default UserPageContainer;