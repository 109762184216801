import React, {useState} from 'react';
import '../../styles/lectureRequestList/lectureRequestTableBody.css';
import {ReactComponent as Share} from '../../images/share.svg';
import {Link} from "react-router-dom";
import Modal from "react-modal";
import styled from "styled-components";
import RejectLectureRequestModal from "./RejectLectureRequestModal";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`;
const LectureRequestTableBody = ({
                                     date, user, title, link, id, setIsLoadedContent
                                 }) => {
    const [buttonDisplay, setButtonDisplay] = useState({display: 'none'});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const bg = {
        overlay: {
            background: '#EBEDF0'
        }
    };
    return (
        <div className={'lectureRequestTableBody'}>
            <div className={'lectureRequestTableRow'} onMouseOver={() => setButtonDisplay({display: 'grid'})}
                 onMouseOut={() => setButtonDisplay({display: 'none'})}>
                <div className={'lectureRequestTableColumn'}>{date}</div>
                <div className={'lectureRequestTableColumn'}>{user}</div>
                <div className={'lectureRequestTableColumn'}>{title}</div>
                <div className={'lectureRequestTableButtonContainer'}
                     style={buttonDisplay}
                >
                    <Link to={`/lectures/applications/${id}`} className={'lectureRequestTableButton'}>
                        등록하기
                    </Link>
                    <a href={link} target={'_blank'} className={'lectureRequestTableButton'}>
                        <div className={'lectureRequestGoButtonContainer'}>
                            <div>바로가기</div>
                            <Share/>
                        </div>
                    </a>
                    <button className={'lectureRequestTableButton'}
                            onClick={() => setIsModalOpen(true)}
                    >반려하기
                    </button>
                </div>
            </div>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isModalOpen}
                styles={bg}
            >
                <RejectLectureRequestModal
                    setIsModalOpen={setIsModalOpen}
                    id={id}
                    setIsLoadedContent={setIsLoadedContent}
                />
            </SModal>
        </div>
    );
};

export default LectureRequestTableBody;