import React from 'react';
import '../../styles/common/header.css'

const Header = ({
    name
                }) => {
    return (
        <h1 className={'mainContainerHeader'}>{name}</h1>
    );
};

export default Header;