import React, {useEffect, useState} from 'react';
import HeaderWithBack from "../../common/HeaderWithBack";
import CategoryTree from "../../common/CategoryTree";
import CategoryContainer from "../category/CategoryContainer";
import EventEditor from "../eventEditor/EventEditor";
import NoticeEditor from "../noticeEditor/NoticeEditor";
import styles from '../../../styles/notice/noticeRegister/noticeRegister.module.css';
import {useLocation} from "react-router-dom";
import qs from "qs";

const NoticeRegisterContainer = () => {
    const [checkedValue, setCheckedValue] = useState(null);
    const location = useLocation();
    const [id, setId] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true
        })
        if (query.id) {
            setId(query.id)
        }
        if (query.category) {
            setCheckedValue(query.category)
            setCategory(query.category)
        }
        console.log(query)
    }, [location])
    return (
        <div>
            <HeaderWithBack name={"공지 등록하기"}/>
            <CategoryTree categories={['공지', '공지 등록하기']}/>
            <div className={styles.container}>
                <CategoryContainer
                    checkedValue={checkedValue}
                    setCheckedValue={setCheckedValue}
                    type={category === null ? 'new' : category === 'event' ? 'editEvent' : 'editNotice'}
                />
                {
                    checkedValue === 'event' ?
                        <EventEditor/> :
                        <NoticeEditor
                            categoryId={checkedValue}
                            setCategory={setCheckedValue}
                        />
                }
            </div>
        </div>

    );
};

export default NoticeRegisterContainer;