import React, {useCallback, useEffect, useState} from 'react';
import Modal from "react-modal";
import styled from "styled-components";
import {request} from "../../util/request";
import {ReactComponent as Check} from "../../images/check.svg";
import styles from '../../styles/notification/notificationModal.module.css'
import {useNavigate} from "react-router-dom";
import UserReviewModal from "../reviewPage/UserReviewModal";
import {useRecoilState} from "recoil";
import {notificationsAtom} from "../../recoil/atom";

const SModal = styled(Modal)`
  left: 468px;
  top: 530px;
  transform: translate(-50%, -50%);
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 416px;
  height: 568px;
  background: #FFFFFF;
  border-radius: 12px;
  overflow: scroll;
  border: 1px solid #EBEDF0;
  @media screen and (max-width: 1728px) {
    left: 308px;
    top: 500px;

  }
`
const NotificationModal = ({setOpen, modalEl, isOpen}) => {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    const [reviewUserId, setReviewUserId] = useState(null);
    const [reviewModalNickname, setReviewModalNickname] = useState(null);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const viewAll = useCallback(() => {
        request.put("/api/v1/admin/notification/viewed", {ids: [...notifications.map((notification) => notification.id)]})
            .then(() => {
            })
            .catch(() => {
            })
        setOpen(false);
        setNotifications([]);
    }, [notifications])

    const clickNotification = useCallback((id, type, url, userId, nickname) => {
        request.put("/api/v1/admin/notification/viewed", {ids: [id]})
            .then(() => {
            })
            .catch(() => {
            })
        if (type === "USER_REVIEW_MODAL") {
            setReviewModalNickname(nickname);
            setReviewUserId(userId);
        } else if (type === "LECTURE_REQUEST") {
            navigate(url);
            setOpen(false);
        }
        setNotifications(notifications.filter((notification) => notification.id !== id));
        setLoaded(false);
    }, [notifications])
    useEffect(() => {
        if (loaded) return;
        request.get("/api/v1/admin/notification")
            .then(({status, data}) => {
                if (status === 200) {
                    setNotifications([...data.notifications])
                    setLoaded(true);
                }
            })
            .catch(() => {
            })
    }, [loaded])

    return (
        <>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={() => setOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <div className={styles.container}>
                    <div className={styles.headerContainer}>
                        <div className={styles.header}>알림</div>
                        <div className={styles.viewedContainer}
                             onClick={() => viewAll()}
                        >
                            <Check/>
                            <div>모두 읽음</div>
                        </div>
                    </div>
                    <div className={styles.notificationContainer}>
                        {notifications.length ?
                            notifications
                                .map(({id, content, nickname, type, url, userId, viewed, createdAt}, index) => (
                                    <div
                                        onClick={() => clickNotification(id, type, url, userId, nickname)}
                                        className={styles.notification}
                                        key={index}>
                                        <div className={styles.dateContainer}>
                                            <div>{createdAt}</div>
                                            {!viewed &&
                                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5"
                                                     viewBox="0 0 5 5"
                                                     fill="none">
                                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#DB2344"/>
                                                </svg>}
                                        </div>
                                        <div>{content}</div>
                                    </div>
                                ))
                            :
                            <div className={styles.noNotification}>
                                등록된 알림이 없어요.
                            </div>}
                    </div>
                </div>
            </SModal>
            {
                reviewUserId &&
                <UserReviewModal
                    userId={reviewUserId}
                    setUserId={setReviewUserId}
                    nickname={reviewModalNickname}
                />
            }
        </>

    );
};

export default NotificationModal;