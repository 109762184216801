import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {login} from "../../util/request";

const AppleLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {

        const searchParams = new URLSearchParams(location.hash)

        if (searchParams.has("id_token")) {
            const idToken = searchParams.get("id_token");
            (async () => {
                const res = await login(idToken, "apple")
                if (res) {
                    navigate('/')
                } else {
                    navigate('/login')
                }
            })();

        }
    }, [location.hash, navigate()])

    return (
        <div>

        </div>
    );
};

export default AppleLogin;