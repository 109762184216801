import {createContext, useState} from "react";
const now = new Date();
const DashboardDateContext = createContext({
    state: {
        startDate: new Date(now.setMonth(now.getMonth() - 3)),
        endDate: new Date()
    },
    action: {
        setStartDate: ()=>{},
        setEndDate: ()=>{}
    }
});

const DashboardDateProvider = ({children})=>{
    const now = new Date();
    const [startDate, setStartDate] = useState(new Date(now.setMonth(now.getMonth() - 3)));
    const [endDate, setEndDate] = useState(new Date());
    const value = {
        state: {startDate, endDate},
        action: {setStartDate, setEndDate}
    };

    return (
        <DashboardDateContext.Provider value={value}>{children}</DashboardDateContext.Provider>
    )
}

const {Consumer: DashboardDateConsumer} = DashboardDateContext;
export {DashboardDateProvider, DashboardDateConsumer};
export default DashboardDateContext;