import React, {useCallback, useEffect, useState} from 'react';
import '../../styles/lectureRequestList/rejectLectureRequestModal.css';
import {ReactComponent as XClose} from "../../images/x-close.svg";
import {ReactComponent as Check} from "../../images/check.svg";
import {request} from "../../util/request";

const RejectLectureRequestModal = ({
                                       setIsModalOpen, setIsLoadedContent, id
                                   }) => {
    const reasons = ['링크가 잘못 등록 되었어요.', '중복된 강의가 있어요.', '해당 강의의 카테고리는 준비중이에요.'];
    const [rejectReason, setRejectReason] = useState(null);
    const [reasonIndex, setReasonIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const selectReason = useCallback((index) => {
        setReasonIndex(index);
        if (index == reasons.length + 1) {
            setRejectReason(null);
        } else {
            setRejectReason(reasons[index]);
        }
    }, []);

    const requestReject = useCallback(() => {
        setIsLoading(true);
        request.delete(`/api/v1/admin/lecture/request/${id}`, {
            data: {
                reason: rejectReason
            }
        })
            .then(({status}) => {
                if (status == 200) {
                    alert('반려하였습니다!')
                    setIsModalOpen(false);
                    setIsLoadedContent(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            })
    }, [id, rejectReason])

    return (
        <div className={'rejectLectureRequestModal'}>
            <div
                className={'rejectLectureRequestHeader'}
            >
                <h1>반려하는 이유는 무엇인가요?</h1>
                <h3>강의를 신청한 사용자에게 반려한 이유를 알려주세요.</h3>
                <div
                    onClick={() => {
                        setIsModalOpen(false);
                    }
                    }
                >
                    <XClose/>
                </div>
            </div>
            <div className={'rejectReasonContainer'}>
                {reasons.map((reason, index) => (
                    <div
                        key={reason}
                        className={reasonIndex == index ? 'rejectReason active' : 'rejectReason'}
                        onClick={() => {
                            selectReason(index);
                        }
                        }>
                        <div className={'rejectReasonCheckSvg'}>
                            <Check></Check>

                        </div>
                        <div className={'rejectReasonText'}>{reason}</div>

                    </div>
                ))}
                <div
                    className={reasonIndex == reasons.length + 1 ? 'rejectReasonDirect active' : 'rejectReason'}
                    onClick={() => {
                        selectReason(reasons.length + 1);
                    }
                    }>
                    <div className={reasonIndex == reasons.length + 1 ? 'rejectReason active' : 'rejectReason'}>
                        <div className={'rejectReasonCheckSvg'}>
                            <Check></Check>
                        </div>
                        <div className={'rejectReasonText'}>직접입력</div>
                    </div>
                    {reasonIndex == reasons.length + 1 &&
                        <input
                            className={'rejectReasonInput'}
                            placeholder={'반려 사유를 입력해주세요.'}
                            onChange={(e) => {
                                setRejectReason(e.target.value);
                            }
                            }
                        />
                    }
                </div>

            </div>
            <div className={'rejectLectureModalButtonContainer'}>
                <button className={'exitButton'}
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                >
                    닫기
                </button>
                <button
                    className={rejectReason != null && !isLoading ? 'rejectButton active' : 'rejectButton'}
                    disabled={rejectReason == null || isLoading}
                    onClick={() => {
                        requestReject();
                    }}
                >
                    반려 사유 등록하기
                </button>
            </div>
        </div>
    );
}

export default RejectLectureRequestModal;