import React, {useEffect, useState} from 'react';
import '../../../styles/home/dashboard/dashboard.css';
import {request} from '../../../util/request.js';
import {toStringByFormatting} from "../../../util/dateUtil";
import {Line} from "react-chartjs-2";
import Chart from 'chart.js/auto';

Chart.getChart();
const Dashboard = ({
                       name,
                       unit,
                       apiEndPoint,
                       dateState
                   }) => {
    const [graphData, setGraphData] = useState({
        currentCount: 0,
        totalCount: 0,
        reviewAverageCount: 0,
        data: []
    });

    useEffect(() => {

        (async () => {

            const {data, status} = await request.get("/api/v1/admin/graph/" + apiEndPoint, {
                params: {
                    startDate: toStringByFormatting(dateState.startDate),
                    endDate: toStringByFormatting(dateState.endDate)
                }
            });
            if (status === 200) {
                setGraphData((prevState) => ({
                    ...prevState,
                    currentCount: data.currentCount,
                    totalCount: data.totalCount,
                    data: data.data,
                    reviewAverageCount: apiEndPoint === 'new-reviews' ? data.reviewAverageCount : 0
                }))
            }

        })()

    }, [dateState])

    return (
        <div className={'dashboardCardContainer'}>
            <div className={"dashboardTitle"}>
                <div className={'dashboardNameContainer'}>
                    <h2 className={'dashboardName'}>{name}</h2>
                    {apiEndPoint == 'new-reviews' &&
                        <div className={'averageReview'}>강의 평균|{graphData.reviewAverageCount}리뷰</div>
                    }
                </div>
                <div className={'dashboardCountContainer'}>
                    <div className={'currentCount'}>{graphData.currentCount}</div>
                    <div className={'totalCount'}>&nbsp;/ {graphData.totalCount} {unit}</div>
                </div>
            </div>
            <div className={'dashboardGraph'}>
                <Line
                    style={{padding:'8px'}}
                    // height={'250px'}
                    data={{
                        type: 'line',
                        datasets: [{
                            label: name,
                            data: graphData.data
                        }]
                    }}/>
            </div>
        </div>
    );
};

export default Dashboard;