import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/search/recentKeywordTable.module.css'
import {ReactComponent as ChevronRight} from "../../images/chevron-right.svg";
import {ReactComponent as ChevronLeft} from "../../images/chevron-left.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {request} from "../../util/request";


const RecentKeywordTable = () => {
    const [keywords, setKeywords] = useState([]);
    const [sort, setSort] = useState('latest');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [paginationPage, setPaginationPage] = useState(0);
    const getKeywords = useCallback(() => {
        request.get( '/api/v1/admin/search/recent',
            {params: {page: currentPage, sort}})
            .then(({status, data}) => {
                if (status == 200) {
                    setCurrentPage(data.currentPage);
                    setTotalPage(data.totalPage);
                    setKeywords([...data.keywords]);
                }
            })
    }, [currentPage, sort])

    const increasePaginationPage = useCallback(() => {
        const totalPaginationPage = Math.ceil((totalPage / 5.0)) - 1;
        if (paginationPage >= totalPaginationPage) return;
        setPaginationPage((prev) => prev + 1)
        setCurrentPage((paginationPage+1)*5)
    }, [paginationPage, totalPage])

    const decreasePaginationPage = useCallback(() => {
        if (paginationPage == 0) return;
        setPaginationPage((prev) => prev - 1)
        setCurrentPage((paginationPage-1)*5+4)

    }, [paginationPage])

    useEffect(() => {
        getKeywords();
    }, [currentPage, sort])
    return (
        <div>
            <div className={styles.topContainer}>
                <h2 className={styles.header}>최근 검색어</h2>
                <select
                    className={styles.sortSelect}
                    onChange={(e) => setSort(e.target.value)}>
                    <option value={'latest'}>최신순</option>
                    <option value={'oldest'}>등록순</option>
                </select>
            </div>

            <table style={{width: '100%'}}>
                <thead>
                <tr className={styles.tableHeader}>
                    <th className={styles.headerColumn}>
                        <div>날짜</div>
                        <div className={styles.sortChevron}>
                            <span
                                className={sort == 'oldest'? styles.active:""}
                                onClick={()=>setSort('oldest')}
                            >
                                <ChevronUp/>
                            </span>
                            <span
                                className={sort == 'latest'? styles.active:""}
                                onClick={()=>setSort('latest')}
                            >
                                <ChevronDown/>
                            </span>
                        </div>
                    </th>
                    <th className={styles.headerColumn}>검색어</th>
                    <th className={styles.headerColumn}>
                        <div>횟수</div>
                        <div className={styles.sortChevron}>
                            <span
                                className={sort == 'count-asc'? styles.active:""}
                                onClick={()=>setSort('count-asc')}

                            >
                                <ChevronUp/>
                            </span>
                            <span
                                className={sort == 'count-desc'? styles.active:""}
                                onClick={()=>setSort('count-desc')}

                            >
                                <ChevronDown/>
                            </span>
                        </div>
                    </th>
                </tr>
                </thead>
                {keywords.map(({date, keyword, count}) => (
                    <tr className={styles.tableRow}>
                        <td className={styles.rowColumn}>{date}</td>
                        <td className={`${styles.rowColumn} ${styles.keywordColumn}`}>{keyword}</td>
                        <td className={styles.rowColumn}>{count.toLocaleString()}회</td>
                    </tr>
                ))}
            </table>
            <div className={styles.pagination}>
                <button className={`${styles.paginationChevron} ${paginationPage == 0 ? styles.disable : ''}`}
                        onClick={() => decreasePaginationPage()}
                        disabled={paginationPage == 0}
                >
                    <ChevronLeft/>
                </button>
                <div className={styles.numberContainer}>
                    {Array
                        .from({length: 5}, (v, i) => i + 1 + paginationPage * 5)
                        .map((page) => (
                            page <= totalPage &&
                            <button
                                className={`${styles.page}  ${page - 1 == currentPage ? styles.active : ''}`}
                                onClick={() => setCurrentPage(page - 1)}>
                                {page}</button>
                        ))
                    }
                </div>
                <button
                    className={`${styles.paginationChevron} ${paginationPage >= Math.ceil((totalPage / 5.0)) - 1 ? styles.disable : ''}`}
                    onClick={() => increasePaginationPage()}
                    disabled={paginationPage >= Math.ceil((totalPage / 5.0)) - 1}
                >
                    <ChevronRight/>
                </button>
            </div>
        </div>
    );
};

export default RecentKeywordTable;