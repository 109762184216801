import React from 'react';
import {ReviewDateConsumer, ReviewDateProvider} from "../context/reviewDateContext";
import ReviewPageContainer from "./ReviewPageContainer";

const ReviewPage = () => {
    return (
        <>
            <ReviewDateProvider>
                <ReviewDateConsumer>
                    {({state, action}) => (
                        <>
                            <ReviewPageContainer
                                dateState={state} dateAction={action}
                            />
                        </>
                    )}
                </ReviewDateConsumer>
            </ReviewDateProvider>
        </>

    );
};

export default ReviewPage;