import React from 'react';
import styles from '../../styles/login/loginPage.module.css';
import {ReactComponent as Logo} from "../../images/logo.svg";
import {ReactComponent as AppleLoginLogo} from "../../images/appleLogin.svg";
import {ReactComponent as KakaoLoginLogo} from "../../images/kakaoLogin.svg";
import {ReactComponent as GoogleLoginLogo} from "../../images/googleLogin.svg";


const LoginPage = () => {
    const redirectUri = 'https://www.admin.onreview.xyz/api/auth/callback'
    // const redirectUri = 'http://localhost:3000/api/auth/callback'

    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=755018634543ff6cde1709f3ab672e96&redirect_uri=${redirectUri}/kakao&response_type=code`;
    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=843040082264-tos4b0nauf387h14pvt5nk26kb3quh6d.apps.googleusercontent.com&redirect_uri=${redirectUri}/google&response_type=token&scope=https://www.googleapis.com/auth/userinfo.email`
    const appleUrl = `https://appleid.apple.com/auth/authorize?response_type=code id_token&response_mode=fragment&client_id=xyz.onreview.client&redirect_uri=${redirectUri}/apple`
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <Logo/>
            </div>
            <div className={styles.btnContainer}>
                <a className={`${styles.btn} ${styles.kakao}`}
                   href={kakaoUrl}>
                    <div className={styles.loginLogo}>
                        <KakaoLoginLogo/>
                    </div>
                    <div>카카오로 시작하기</div>
                    <div></div>
                </a>
                <a className={`${styles.btn} ${styles.google}`}
                   href={googleUrl}>
                    <div className={styles.loginLogo}>
                        <GoogleLoginLogo/>
                    </div>
                    <div>Google로 시작하기</div>
                    <div></div>
                </a>
                <a className={`${styles.btn} ${styles.apple}`}
                   href={appleUrl}>
                    <div className={styles.loginLogo}>
                        <AppleLoginLogo/>
                    </div>
                    <div>Apple로 시작하기</div>
                    <div></div>
                </a>
            </div>
        </div>
    );
};

export default LoginPage;