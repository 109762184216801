import React from 'react';
import SideBarContainer from "../sideBar/SideBarContainer";
import AuthenticationContainer from "../authentication/AuthenticationContainer";
import {Route, Routes, useLocation} from "react-router-dom";
import HomeContainer from "../home/HomeContainer";
import LoginPage from "../login/LoginPage";
import KakaoLogin from "../login/KakaoLogin";
import GoogleLogin from "../login/GoogleLogin";
import AppleLogin from "../login/AppleLogin";
import LectureListContainer from "../lectureList/LectureListContainer";
import LectureRequestContainer from "../LectureRequest/LectureRequestContainer";
import LectureRequestListContainer from "../lectureRequestList/LectureRequestListContainer";
import ReviewPage from "../reviewPage/ReviewPage";
import UserPageContainer from "../userPage/UserPageContainer";
import SearchContainer from "../search/SearchContainer";
import NoticeRegisterContainer from "../notice/noticeRegister/NoticeRegisterContainer";
import NoticeAndFaqPage from "../noticeAndFaqPage/NoticeAndFaqPage";
import FaqEditor from "../faq/FAQEditor";

const RoutesContainer = () => {
    const location = useLocation();
    return (
        <div className={'indexContainer'}>
            {!(location.pathname.includes('/login') || location.pathname.includes('/api/auth/callback/')) && <SideBarContainer></SideBarContainer>}
            <div className={!(location.pathname.includes('/login') || location.pathname.includes('/api/auth/callback/'))?'mainContainer':''}>
                <AuthenticationContainer>
                    <Routes>
                        <Route path="/" element={<HomeContainer/>}></Route>
                        <Route path={"/login"} element={<LoginPage/>}></Route>
                        <Route path={"/api/auth/callback/kakao"} element={<KakaoLogin/>}></Route>
                        <Route path={'/api/auth/callback/google'} element={<GoogleLogin/>}></Route>
                        <Route path={'/api/auth/callback/apple'} element={<AppleLogin/>}></Route>

                        <Route path="/alarm" element={<div>알림</div>}></Route>
                        <Route path="/lectures" element={<LectureListContainer/>}></Route>
                        <Route path="/lectures/:id"
                               element={<LectureRequestContainer type={'editLecture'}/>}></Route>
                        <Route path="/lectures/applications"
                               element={<LectureRequestListContainer/>}></Route>
                        <Route path="/reviews" element={<ReviewPage/>}></Route>
                        <Route path="/users" element={<UserPageContainer/>}></Route>
                        <Route path="/search" element={<SearchContainer/>}></Route>
                        <Route path="/notice-faq" element={<NoticeAndFaqPage/>}></Route>
                        <Route path="/alliance" element={<div>제휴</div>}></Route>
                        <Route path={"/lectures/applications/new"}
                               element={<LectureRequestContainer type={'adminRequest'}/>}></Route>
                        <Route path={"/lectures/applications/:id"}
                               element={<LectureRequestContainer type={'userRequest'}/>}></Route>
                        <Route path={"/notice/editor"} element={<NoticeRegisterContainer/>}/>
                        <Route path={"/faq/editor"} element={<FaqEditor/>}></Route>
                    </Routes>
                </AuthenticationContainer>

            </div>
        </div>
    );
};

export default RoutesContainer;