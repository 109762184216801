import React, {useEffect} from 'react';
import '../../styles/sideBar/sideBarContainer.css';
import MenuButton from "./MenuButton";
import {ReactComponent as Bell} from '../../images/bell.svg'
import {ReactComponent as Home} from '../../images/home.svg'
import {ReactComponent as Inbox} from '../../images/inbox.svg'
import {ReactComponent as LogOut} from '../../images/log-out.svg'
import {ReactComponent as MessageSmileCircle} from '../../images/message-smile-circle.svg'
import {ReactComponent as Announcement} from '../../images/announcement.svg'
import {ReactComponent as ThumbsUp} from '../../images/thumbs-up.svg'
import {ReactComponent as Tv} from '../../images/tv.svg'
import {ReactComponent as User} from '../../images/user.svg'
import {ReactComponent as Search} from '../../images/search.svg'
import {Link} from "react-router-dom";
import {logout, request} from "../../util/request";
import NotificationConatiner from "../notification/NotificationConatiner";
import {useRecoilState} from "recoil";
import {lectureRequestCntAtom, notificationsAtom} from "../../recoil/atom";


const SideBarContainer = () => {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom)
    const [lectureRequestCnt, setLectureRequestCnt] = useRecoilState(lectureRequestCntAtom);
    const menuListTop = [
        {name: '홈', src: <Home/>, to: '/'},
        {name: '알림', src: <Bell/>, to: '/alarm'},
        {name: '강의 목록', src: <Tv/>, to: '/lectures'},
        {name: '신청 목록', src: <Inbox/>, to: '/lectures/applications'},
        {name: '리뷰', src: <ThumbsUp/>, to: '/reviews'},
        {name: '사용자', src: <User/>, to: '/users'},
        {name: '검색', src: <Search/>, to: '/search'},
    ]

    const menuListBottom = [
        {name: '공지 및 FAQ', src: <Announcement/>, to: '/notice-faq'},
        {name: '제휴', src: <MessageSmileCircle/>, to: '/alliance'},
    ]

    useEffect(() => {
        request.get("/api/v1/admin/notification")
            .then(({status, data}) => {
                if (status === 200) {
                    setNotifications([...data.notifications])
                }
            })
            .catch(() => {
            })
    }, [])

    useEffect(() => {
        request.get("/api/v1/admin/lecture/request/all")
            .then(({status, data}) => {
                if (status === 200) {
                    setLectureRequestCnt(data.totalCount);
                    console.log(data.totalCount)
                }
            })
            .catch(() => {
            })
    }, [])


    return (
        <div className={"sideBar"}>
            <div className={"sideBarContainer"}>
                <object className={'mainLogo'}
                        data={"https://on-review-admin-images.s3.ap-northeast-2.amazonaws.com/logo/mainLogo.svg"}></object>
                {menuListTop.map(menu => {
                    if (menu.name === '알림') {
                        return <NotificationConatiner/>
                    }
                    return (<Link
                        key={menu.name}
                        to={menu.to} style={{textDecoration: "none"}}>
                        <MenuButton svgComponent={menu.src} menuName={menu.name} to={menu.to}></MenuButton>
                    </Link>)
                })}
                <div className={'line'}></div>
                <h2 className={'bottomMenuName'}>문의</h2>
                {menuListBottom.map(menu => (
                    <Link
                        key={menu.name}
                        to={menu.to} style={{textDecoration: "none"}}>
                        <MenuButton svgComponent={menu.src} menuName={menu.name} to={menu.to}></MenuButton>
                    </Link>
                ))}
                <div className={'logOutContainer'}>
                    <div onClick={() => logout()}>
                        <MenuButton svgComponent={<LogOut/>} menuName={"로그아웃"}></MenuButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBarContainer;