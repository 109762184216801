import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import '../../styles/lectureRequest/retouchButton.css';
import {request} from "../../util/request";

const RetouchButton = ({
                           lectureId,
                           link,
                           price,
                           discountPrice,
                           discountRate,
                           platformId,
                           categoryId,
                           name,
                           instructor,
                           collectionPeriod,
                           detail
                       }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isAllFilled, setIsAllFilled] = useState(false);
    const retouchLecture = useCallback(() => {
        setIsLoading(true);
        request.put(`/api/v1/admin/lecture/${lectureId}`, {
            link,
            price: Number(price.replaceAll(',', '')),
            discountPrice: Number(discountPrice.replaceAll(',', '')),
            discountRate,
            platformId,
            categoryId,
            title: name,
            instructor,
            collectionPeriod,
            introduction: detail
        }).then(({status, data}) => {
            if (status == 200) {
                alert('강의를 수정했습니다!');
                navigate(-1);
            } else {
                alert('강의 수정에 실패했습니다!')
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            alert('강의 수정에 실패했습니다!')
            setIsLoading(false);
        })
    }, [lectureId,
        link,
        price,
        discountPrice,
        discountRate,
        platformId,
        categoryId,
        name,
        instructor,
        collectionPeriod,
        detail])
    useEffect(() => {

        if (lectureId &&
            link &&
            platformId &&
            categoryId &&
            name &&
            instructor &&
            collectionPeriod &&
            detail) {
            setIsAllFilled(true);
        } else {
            setIsAllFilled(false);
        }
    }, [lectureId,
        link,
        platformId,
        categoryId,
        name,
        instructor,
        collectionPeriod,
        detail])
    return (
        <button
            className={!isLoading && isAllFilled ? 'lectureRetouchButton active' : 'lectureRetouchButton'}
            onClick={() => {
                retouchLecture()
            }}
            disabled={isLoading || !isAllFilled}
        >
            수정하기
        </button>
    );
};

export default RetouchButton;