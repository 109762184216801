import React, {useCallback, useState} from 'react';
import styles from '../../styles/lectureRequest/deleteButton.module.css'
import Modal from 'react-modal';
import styled from "styled-components";
import {ReactComponent as Close} from "../../images/x-close.svg";
import {useNavigate} from "react-router-dom";
import {request} from "../../util/request";
const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const DeleteButton = ({
                          lectureId
                      }) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const onClickDeleteBtn = useCallback(() => {
        setModalOpen(true);
    }, [])
    const onClickExitBtn = useCallback(() => {
        setModalOpen(false);
    }, [])
    const onClickModalDeleteBtn = useCallback(() => {
        request.delete( `/api/v1/admin/lecture/${lectureId}`)
            .then(({status, data}) => {
                if (status === 200) {
                    alert('삭제 했습니다!')
                    navigate(-1);
                    return;
                }
                alert("삭제에 실패했습니다")
            })
            .catch((err)=>{
                console.log('lecture delete err: ' + err);
                alert("삭제에 실패했습니다")
            });
    }, [lectureId]);
    return (
        <>
            <button
                onClick={() => onClickDeleteBtn()}
                className={styles.deleteButton}
            >
                삭제하기
            </button>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <div className={styles.modal}>
                    <div
                        className={styles.closeBtn}
                        onClick={() => onClickExitBtn()}
                    >
                        <Close></Close>
                    </div>
                    <div className={styles.header}>앗...작성했던 내용이 사라져요.</div>
                    <div className={styles.body}>삭제하면 작성했던 내용이 사라져요. 정말로 삭제하시나요?</div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.modalExitButton}
                            onClick={() => onClickExitBtn()}>닫기
                        </button>
                        <button
                            onClick={()=>{onClickModalDeleteBtn()}}
                            className={styles.modalDeleteButton}
                        >삭제하기
                        </button>
                    </div>
                </div>
            </SModal>
        </>

    );
};

export default DeleteButton;