import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import LoginPage from "../login/LoginPage";

const AuthenticationContainer = ({children}) => {
    const navigate = useNavigate()
    const location = useLocation();
    // const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (location.pathname !== "/login"
            && !location.pathname.includes("/api/auth/callback/")
            && (accessToken == null)) {
            console.log("accessToken:", accessToken)
            navigate('/login')
        }
    }, [location.pathname])
    return (
        <>
            {(location.pathname !== "/login"
            && !location.pathname.includes("/api/auth/callback/")
            && localStorage.getItem('accessToken') == null) ?  <LoginPage/>: children}
        </>
    );
};

export default AuthenticationContainer;