import React, {useCallback, useEffect, useState} from 'react';
import Header from "../common/Header";
import {ReactComponent as SearchIcon} from "../../images/search-md.svg";
import {request} from "../../util/request";
import '../../styles/lectureList/lectureListContainer.css'
import {Link} from "react-router-dom";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";
import LectureTableRow from "./LectureTableRow";

const LectureListContainer = () => {
    const [totalLecture, setTotalLecture] = useState(0);
    const [platforms, setPlatforms] = useState([]);
    const [query, setQuery] = useState('');
    const [platformId, setPlatformId] = useState('all');
    const [lectures, setLectures] = useState([]);
    const [isDateDesc, setIsDateDesc] = useState(null);
    const [isReviewDesc, setIsReviewDesc] = useState(null);



    const onChangeQuery = useCallback((e) => {
        setQuery(e.target.value);
    }, []);

    const onClickDateDesc = useCallback(() => {
        setLectures([...lectures.sort((a, b) => {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            if (a.date === b.date) return 0;
        })]);
        setIsDateDesc(true);
        setIsReviewDesc(null);
    }, [lectures])

    const onClickDateAsc = useCallback(() => {
        setLectures([...lectures.sort((a, b) => a.date.toLowerCase() < b.date.toLowerCase() ? -1 : 1)])
        setIsDateDesc(false);
        setIsReviewDesc(null);
    }, [lectures])

    const onClickReviewDesc = useCallback(() => {
        setLectures([...lectures.sort((a, b) => {
            if (a.reviewCount < b.reviewCount) return 1;
            if (a.reviewCount > b.reviewCount) return -1;
            if (a.reviewCount === b.reviewCount) return 0;
        })]);
        setIsReviewDesc(true);
        setIsDateDesc(null);
    }, [lectures])

    const onClickReviewAsc = useCallback(() => {
        setLectures([...lectures.sort((a, b) => a.reviewCount < b.reviewCount ? -1 : 1)])
        setIsReviewDesc(false);
        setIsDateDesc(null);
    }, [lectures])

    const search = useCallback(() => {
        setIsDateDesc(null);
        setIsReviewDesc(null);
        request.get(`/api/v1/admin/lecture/search`, {
            params: {q: query, platformId}
        })
            .then(({status, data}) => {
                if (status === 200) {
                    setLectures([...data.lectures]);
                    setTotalLecture(data.totalCount);
                }
            })
    }, [query, platformId])


    const searchByPlatformId = useCallback((platformId) => {
        setIsDateDesc(null);
        setIsReviewDesc(null);
        request.get(`/api/v1/admin/lecture/search`, {
            params: {q: query, platformId}
        })
            .then(({status, data}) => {
                if (status === 200) {
                    setLectures([...data.lectures]);
                    setTotalLecture(data.totalCount);
                }
            })
    }, [query])

    const onChangePlatform = useCallback((e) => {
        const platformId = e.target.value;
        setPlatformId(platformId);
        searchByPlatformId(platformId)
    }, [searchByPlatformId]);

    useEffect(() => {
        request.get(`/api/v1/admin/lecture/all`)
            .then(({data, status}) => {
                if (status === 200) {
                    setLectures([...data.lectures]);
                    setTotalLecture(data.totalCount);
                }
            })
    }, [])

    useEffect(() => {
        request.get(`/api/v1/platform`)
            .then(({data, status}) => {
                if (status == 200) {
                    setPlatforms([...data.platforms])
                }
            })
    }, [])
    return (
        <div className={'lectureListContainer'}>
            <Header name={'강의 목록'}/>
            <div className={'lectureListTopContainer'}>
                <div
                    className={'lectureSearchContainer'}
                >
                    <select className={'lectureListPlatformDropDown'} onChange={(e) => onChangePlatform(e)}>
                        <option value={'all'}>강의 플랫폼 전체</option>
                        {platforms.map((platform) => (
                            <option key={platform.id} value={platform.id}>{platform.name}</option>
                        ))}
                    </select>
                    <div className={'lectureSearchInputContainer'}>
                        <input
                            className={'lectureSearchInput'}
                            placeholder={'강의명, 튜터를 검색하세요.'}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    search();
                                }
                            }}
                            onChange={
                                (e) => {
                                    onChangeQuery(e)
                                }
                            }
                        />
                        <div className={'searchSvg'}
                             onClick={() => {
                                 search();
                             }}
                        >
                            <SearchIcon/>
                        </div>
                    </div>
                    <div className={'lectureTotalContainer'}>
                        총{' '}
                        <span className={'lectureTotal'}>
                            {totalLecture}
                        </span>
                        {' '}강의
                    </div>
                </div>
                <Link to={'/lectures/applications/new'} style={{'textDecoration': 'none'}}>
                    <div className={'registerNewLecture'}>
                        강의 등록하기
                    </div>
                </Link>
            </div>
            <div className={'lectureListTableHeader'}>
                <div className={'lectureListTableColumn'}>
                    <div>날짜</div>
                    <div className={'lectureTableSort'}>
                        <div
                            className={isDateDesc === null ? '' : !isDateDesc ? 'active' : ''}
                        >
                            <ChevronUp
                                onClick={() => {
                                    onClickDateAsc()
                                }}
                            ></ChevronUp>
                        </div>
                        <div
                            className={isDateDesc === null ? '' : isDateDesc ? 'active' : ''}
                        >
                            <ChevronDown
                                onClick={() => {
                                    onClickDateDesc()
                                }}
                            ></ChevronDown>
                        </div>
                    </div>
                </div>
                <div className={'lectureListTableColumn'}>카테고리</div>
                <div className={'lectureListTableColumn'}>강의 플랫폼</div>
                <div className={'lectureListTableColumn'}>강의명</div>
                <div className={'lectureListTableColumn'}>튜터</div>
                <div className={'lectureListTableColumn'}>
                    <div>리뷰수</div>
                    <div className={'lectureTableSort'}>
                        <div
                            className={isReviewDesc === null ? '' : !isReviewDesc ? 'active' : ''}
                        >
                            <ChevronUp
                                onClick={() => {
                                    onClickReviewAsc()
                                }}
                            ></ChevronUp>
                        </div>
                        <div
                            className={isReviewDesc === null ? '' : isReviewDesc ? 'active' : ''}
                        >
                            <ChevronDown
                                onClick={() => {
                                    onClickReviewDesc()
                                }}
                            ></ChevronDown>
                        </div>
                    </div>
                </div>
                <div className={'lectureListTableColumn'}>노출 여부</div>
            </div>
            {lectures.map(({id, date, category, platform, title, instructor, reviewCount, displayed}) => (
                <LectureTableRow
                    key={id}
                    id={id}
                    date={date}
                    category={category}
                    platform={platform}
                    title={title}
                    instructor={instructor}
                    reviewCount={reviewCount}
                    displayed={displayed}
                />
            ))}
        </div>
    );
};

export default LectureListContainer;