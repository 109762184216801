import React, {useCallback, useEffect, useState} from 'react';
import '../../styles/lectureRequest/categoryModal.css';
import {ReactComponent as XClose} from "../../images/x-close.svg";
import {request} from "../../util/request";

const CategoryModal = ({
                           setIsOpenModal,
                           setLoaded
                       }) => {
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onClickRegister = useCallback(() => {
        if (name) {
            setIsLoading(true);
            request.post( '/api/v1/category', {
                name
            })
                .then(({status}) => {
                    if (status === 201) {
                        setIsOpenModal(false)
                        setLoaded(false)
                    }else{
                        alert('플랫폼 추가 실패')
                    }
                    setIsLoading(false)
                })
                .catch((err)=>{
                    alert('플랫폼 추가 실패')
                    setIsLoading(false)
                });
        }
    }, [name])
    useEffect(() => {
        if (name) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [name])
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.key === 'Escape') {
                setIsOpenModal(false);
            }
        };
        window.addEventListener("keyup", escKeyModalClose);
        return () => window.removeEventListener("keyup", escKeyModalClose);
    }, [])
    return (
        <div className={'categoryModal'}>
            <div className={'categoryModalXCloseSvg'}
                 onClick={() => {
                     setIsOpenModal(false);
                 }}
            >
                <XClose></XClose>
            </div>
            <div className={'categoryModalHeader'}>
                카테고리 추가하기
            </div>
            <div className={'categoryModalNameContainer'}>
                <div>카테고리 이름</div>
                <input
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }
                    }
                    className={'categoryModalName'}
                    placeholder={'이름을 입력해주세요.'}
                />
            </div>
            <div className={'categoryModalButtonContainer'}>
                <button
                    className={'categoryModalExitButton'}
                    onClick={() => {
                        setIsOpenModal(false);
                    }}
                >
                    취소
                </button>
                <button
                    className={'categoryModalAppendButton ' + (isActive ? 'active' : '')}
                    disabled={!isActive}
                    onClick={() => {
                        onClickRegister();
                    }}
                >
                    추가하기
                </button>
            </div>
        </div>
    );
};

export default CategoryModal;