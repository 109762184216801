import React from 'react';
import Header from "../../common/Header";
import '../../../styles/home/dashboard/dashboardContainer.css'
import Calender from "./Calender";
import Dashboard from "./Dashboard";
import {DashboardDateConsumer, DashboardDateProvider} from "../../context/dashboardDateContext";

const DashboardContainer = () => {
    return (
        <div className={'dashboardContainer'}>
            <DashboardDateProvider>
                <DashboardDateConsumer>
                    {({state, action}) => (
                        <>
                            <div className={'dashboardHeader'}>
                                <Header name={'대시보드'}/>
                                <Calender dateState={state} dateAction={action}/>
                            </div>
                            <div className={'dashboardFlex'}>
                                <Dashboard dateState={state} name={'신규 사용자'} unit={'명'} apiEndPoint={'new-users'}/>
                                <Dashboard dateState={state} name={'강의 수'} unit={'강의'} apiEndPoint={'new-lectures'}/>
                                <Dashboard dateState={state} name={'신규 리뷰 수'} unit={'리뷰'} apiEndPoint={'new-reviews'}/>
                                <Dashboard dateState={state} name={'페이지 뷰'} unit={'뷰'} apiEndPoint={'new-views'}/>
                            </div>
                        </>
                    )}
                </DashboardDateConsumer>
            </DashboardDateProvider>


        </div>
    );
};

export default DashboardContainer;