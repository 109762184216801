import axios from 'axios';
import {setRecoil} from "recoil-nexus";
import {accessTokenAtom, refreshTokenAtom} from "../recoil/atom";

export const API_URL = 'https://api.onreview.xyz';
// export const API_URL = 'http://localhost:8080'


const requestRefreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
        const {data, status} = await axios.get(API_URL + "/api/v1/auth/token/refresh", {
            headers: {
                Authorization: 'Bearer ' + refreshToken
            }
        });
        if (status !== 200) {
            setRecoil(refreshTokenAtom, null);
            setRecoil(accessTokenAtom, null);
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            return {data: null, status};
        } else {
            setRecoil(refreshTokenAtom, data.refreshToken);
            setRecoil(accessTokenAtom, data.token);
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            return {data, status};
        }
    } catch (e) {
        setRecoil(refreshTokenAtom, null);
        setRecoil(accessTokenAtom, null);
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        return {data: null, status: 401};
    }


}

const request = axios.create({
    baseURL: API_URL,
    timeout: 30000,
});

request.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (
            'response' in error &&
            (error.response.status === 403 || error.response.status === 401) &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            const {data, status} = await requestRefreshToken();
            if (data === null) {
                window.location.href = '/login'
                return {data, status};
            }
            console.log("test")
            request.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
            console.log(JSON.stringify(originalRequest))
            return axios(originalRequest);
        }
        return Promise.reject(error);
    },
);
const setAuthorization = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        request.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }
}
setAuthorization()

export {request};


export const login = async (oauthToken, provider) => {
    const headers = {
        Authorization: "Bearer " + oauthToken
    }
    const {
        data,
        status
    } = await axios.get(API_URL + "/api/v1/auth/login?provider=" + provider, {headers})
    if (status === 200) {
        const {registered, token, refreshToken, authority} = data;
        if (!registered) {
            alert("등록되지 않은 사용자")
            return false;
        } else if (authority !== 'ADMIN') {
            alert("어드민 계정이 아닙니다!")
            return false;
        } else {
            localStorage.setItem('accessToken', token);
            localStorage.setItem('refreshToken', refreshToken);
            setRecoil(accessTokenAtom, token);
            setRecoil(refreshTokenAtom, refreshToken);
            request.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            return true;
        }

    }
}

export const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) return;
    await axios.delete(API_URL + '/api/v1/auth/logout', {
        headers: {Authorization: 'Bearer ' + refreshToken}
    })
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
}