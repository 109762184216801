import React, {useState} from 'react';
import styles from '../../styles/common/headerWithBack.module.css';
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import Header from "./Header";
import {ReactComponent as Close} from "../../images/x-close.svg";
import {ReactComponent as ChevronLeft} from "../../images/chevron-left.svg";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  gap: 10px;
  position: absolute;
  width: 480px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
`
const HeaderWithBack = ({
                            name
                        }) => {
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    return (
        <>
            <SModal
                style={{
                    overlay: {
                        backgroundColor: "#00000066"
                    }}}
                isOpen={isModalOpen}
                ariaHideApp={false}
                onRequestClose={() => setModalOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <div className={styles.modal}>
                    <div
                        className={styles.closeBtn}
                        onClick={() => setModalOpen(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className={styles.header}>앗...작성했던 내용이 사라져요.</div>
                    <div className={styles.body}>뒤로가면 작성했던 내용이 사라져요. 정말로 뒤로가시나요?</div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.modalExitButton}
                            onClick={() => setModalOpen(false)}>닫기
                        </button>
                        <button
                            onClick={() => {
                                navigate(-1)
                            }}
                            className={styles.modalDeleteButton}
                        >뒤로가기
                        </button>
                    </div>
                </div>
            </SModal>
            <div className={styles.container}>
                <div className={styles.chevronLeft} onClick={() => {
                    setModalOpen(true);
                }}>
                    <ChevronLeft/>
                </div>
                <Header name={name}/>
            </div>
        </>

    );
};

export default HeaderWithBack;