import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import Modal from "react-modal";
import {ReactComponent as XClose} from "../../images/x-close.svg";

import styles from '../../styles/userPage/accessHistoryModal.module.css'
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";
import {request} from "../../util/request";

const SModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 10px;
  position: absolute;
  width: 600px;
  height: 700px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EBEDF0;
  overflow: scroll;
`

const AccessHistoryModal = ({isOpen, setOpen, userId}) => {
    const [page, setPage] = useState(-1);
    const [sort, setSort] = useState('latest');
    const [histories, setHistories] = useState([]);
    const [nickname, setNickname] = useState('')
    const [totalPage, setTotalPage] = useState(1);

    const getHistories = useCallback(() => {
        if (isOpen && page + 1 < totalPage) {
            request.get(`/api/v1/admin/user/access-history/${userId}`, {params: {page: page + 1, sort}})
                .then(({status, data}) => {
                    setHistories([...histories, ...data.histories])
                    setPage(page + 1)
                    setNickname(data.nickname)
                    setTotalPage(data.totalPage)
                })
        }

    }, [page, sort, isOpen, totalPage, histories])
    useEffect(() => {
        if (isOpen) {
            request.get(`/api/v1/admin/user/access-history/${userId}`, {params: {page: 0, sort}})
                .then(({status, data}) => {
                    setHistories([...data.histories])
                    setPage(0)
                    setNickname(data.nickname)
                    setTotalPage(data.totalPage)
                })
        }
    }, [isOpen, sort])
    return (
        <SModal
            style={{
                overlay: {
                    backgroundColor: "#00000066"
                }}}
            isOpen={isOpen}
        >
            <div>
                <div className={styles.header}>
                    <div>
                        {nickname} 로그
                    </div>
                    <div
                        className={styles.close}
                        onClick={() => setOpen(false)}>
                        <XClose/>
                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.table}
                    >
                        <thead>
                        <tr className={styles.headerRow}>
                            <td className={styles.headerColumn}>
                                <div className={styles.dateColumn}>
                                    <div>
                                        로그인 날짜
                                    </div>
                                    <div className={styles.sortColumn}>
                                        <div className={`${styles.sort} ${sort === 'oldest' ? styles.active : ''}`}
                                             onClick={() => setSort('oldest')}
                                        >
                                            <ChevronUp/>
                                        </div>
                                        <div className={`${styles.sort} ${sort === 'latest' ? styles.active : ''}`}
                                             onClick={() => setSort('latest')}

                                        >
                                            <ChevronDown/>
                                        </div>
                                    </div>
                                </div>

                            </td>
                            <td className={styles.headerColumn}>
                                접속 IP
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {histories.map(({loginDate, ip}) => (
                            <tr className={styles.bodyRow}>
                                <td className={styles.bodyColumn}>{loginDate}</td>
                                <td className={styles.bodyColumn}>{ip}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
                <div className={styles.moreBtnContainer}
                     onClick={() => getHistories()}
                >
                    {page + 1 < totalPage &&
                        <button className={styles.moreBtn}>
                            <div>더보기</div>
                            <div className={styles.moreBtnSvg}>
                                <ChevronDown/>
                            </div>
                        </button>
                    }

                </div>
            </div>

        </SModal>
    );
};

export default AccessHistoryModal;