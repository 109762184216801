import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/faq/faqEditor.module.css';
import HeaderWithBack from "../common/HeaderWithBack";
import CategoryTree from "../common/CategoryTree";
import CategoryContainer from "../notice/category/CategoryContainer";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import SelectBoxContainer from "../common/SelectBoxContainer";
import {request} from "../../util/request";
import FormInputText from "../common/FormInputText";

const FaqEditor = () => {
    const navigate = useNavigate();
    const [checkedValue, setCheckedValue] = useState(null);
    const location = useLocation();
    const [id, setId] = useState(null);
    const [isLoaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([])
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true
        })
        if (query.id) {
            setId(query.id)
        }
        if (query.category) {
            setCheckedValue(query.category)
        }
    }, [location])

    useEffect(() => {
        if (id !== null) {
            request.get('/api/v1/faq/' + id)
                .then(({status, data}) => {
                    if (status === 200) {
                        setQuestion(data.question);
                        setAnswer(data.answer);
                    }
                })
                .catch(() => {
                })
        }
    }, [id])

    useEffect(() => {
        if (isLoaded) return;
        request.get("/api/v1/admin/faq/category")
            .then(({data, status}) => {
                setCategories(data.categories)
                setLoaded(true);
            })
            .catch(() => {
            })
    }, [isLoaded])

    const postFaq = useCallback(() => {
        if (!(checkedValue && question && answer)) return;
        setLoading(true)
        request.post('/api/v1/admin/faq', {
            categoryId: checkedValue,
            question,
            answer
        })
            .then(({status, data}) => {
                if (status === 201) {
                    alert('FAQ 등록 성공!')
                    navigate('/notice-faq');
                    return;
                }
                alert('FAQ 등록 실패했습니다!')

            })
            .catch(() => {
                alert('FAQ 등록 실패했습니다!')
            })
            .finally(() => {
                setLoading(false);
            })
    }, [question, answer, checkedValue])

    const deleteFaq = useCallback(() => {
        setLoading(true);
        request.delete('/api/v1/admin/faq/' + id)
            .then(({status}) => {
                if (status === 200) {
                    alert("FAQ 삭제 성공!")
                    navigate('/notice-faq');
                } else {
                    alert("FAQ 삭제 실패!")
                }
            })
            .catch(() => {
                alert("FAQ 삭제 실패!")
            })
            .finally(() => setLoading(false))
    }, [id])

    const updateFaq = useCallback(() => {
        setLoading(true)
        request.put('/api/v1/admin/faq/' + id, {
            categoryId: checkedValue,
            question,
            answer
        })
            .then(({status}) => {
                if (status === 200) {
                    alert("faq 수정 성공!")
                    navigate('/notice-faq')
                } else {
                    alert("faq 수정 실패!")
                }
            })
            .catch(() => {
                alert("faq 수정 실패!")
            })
            .finally(() => {
                setLoading(false)
            })
    }, [question, answer, checkedValue])
    return (
        <>
            <div>
                <HeaderWithBack name={"FAQ 등록하기"}/>
                <CategoryTree categories={['FAQ', 'FAQ 등록하기']}/>
            </div>
            <div className={styles.container}>

                <SelectBoxContainer
                    name={'카테고리'}
                    items={categories}
                    setCheckedValue={setCheckedValue}
                    checkedValue={checkedValue}
                    setLoaded={setLoaded}
                    selectBoxType={'faq'}
                />
                <FormInputText
                    name={'제목'}
                    placeholder={'FAQ 제목을 입력해주세요.'}
                    type={'text'}
                    setValue={setQuestion}
                    value={question}
                />
                <div>
                    <div className={'lectureRequestDetailContainer'}>
                        <div className={'lectureRequestDetailTitle'}>내용</div>
                        <div className={'lectureRequestDetail'}>
                        <textarea
                            className={'lectureRequestDetailText'}
                            placeholder={'내용을 입력해주세요.'}
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        ></textarea>
                        </div>

                    </div>
                </div>
                <div className={`${styles.buttonContainer}`}>
                    {
                        !id ?
                            <>
                                <button
                                    className={styles.exitButton}
                                    disabled={isLoading}
                                    onClick={() => navigate('/notice-faq')}
                                >취소하기
                                </button>
                                <button
                                    className={`${styles.registerButton} ${!(question && answer && checkedValue) ? '' : styles.active}`}
                                    onClick={() => {
                                        postFaq()
                                    }}
                                    disabled={isLoading || !(question && answer && checkedValue)}
                                >등록하기
                                </button>
                            </>
                            :
                            <>
                                <button
                                    className={styles.exitButton}
                                    disabled={isLoading}
                                    onClick={() => {
                                        deleteFaq()
                                    }}
                                >삭제하기
                                </button>
                                <button
                                    className={`${styles.registerButton} ${!(question && answer && checkedValue) ? '' : styles.active}`}
                                    onClick={() => {
                                        updateFaq()
                                    }}
                                    disabled={isLoading || !(question && answer && checkedValue)}
                                >수정하기
                                </button>
                            </>
                    }


                </div>
            </div>
        </>

    );
};

export default FaqEditor;