import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import '../../styles/lectureRequest/registerLectureRequestButton.css';
import {request} from "../../util/request";

const RegisterLectureRequestButton = ({
                                          lectureRequestId,
                                          link,
                                          price,
                                          discountPrice,
                                          discountRate,
                                          platformId,
                                          categoryId,
                                          name,
                                          instructor,
                                          collectionPeriod,
                                          detail
                                      }) => {
    const [isLoaded, setLoaded] = useState(false);
    const [isNotNullField, setIsNotNullField] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
    }, [isLoaded, isNotNullField])
    useEffect(() => {
        if (
            lectureRequestId &&
            link &&
            platformId &&
            categoryId &&
            name &&
            instructor &&
            collectionPeriod &&
            detail) {
            setIsNotNullField(true);
        } else {
            setIsNotNullField(false);
        }

    }, [
        lectureRequestId,
        link,
        platformId,
        categoryId,
        name,
        instructor,
        collectionPeriod,
        detail
    ])

    const clickRegister = useCallback(() => {
        setLoaded(true);
        request.post(`/api/v1/admin/lecture/request/${lectureRequestId}`, {
            link,
            price: Number(price.replaceAll(',', '')),
            discountPrice: Number(discountPrice.replaceAll(',', '')),
            discountRate,
            platformId,
            categoryId,
            name,
            instructor,
            collectionPeriod,
            introduction: detail
        })
            .then(({status}) => {
                if (status == 201) {
                    alert("등록 되었습니다!");
                    setLoaded(false)
                    navigate(-1);
                } else {
                    alert('잘못 입력하셨습니다!')
                    setLoaded(false)
                }

            })
            .catch(() => {
                alert('잘못 입력하셨습니다!')
                setLoaded(false)
            })
        ;
    }, [lectureRequestId, link, price, discountPrice, discountRate, platformId, categoryId, name, instructor, collectionPeriod, detail])

    return (<button
        className={(!isLoaded && isNotNullField) ? 'registerLectureRequestButton active' : 'registerLectureRequestButton'}
        onClick={() => {
            clickRegister()
        }}
        disabled={isLoaded || !isNotNullField}
    >
        등록하기
    </button>);
};

export default RegisterLectureRequestButton;