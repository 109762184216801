import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {login} from "../../util/request";

const GoogleLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const start = location.hash.indexOf('=') + 1;
        const end = location.hash.indexOf('&');
        const token = location.hash.slice(start, end);
        (async () => {
            const res = await login(token, 'google')
            if (res) {
                navigate('/')
            } else {
                navigate('/login')
            }

        })()
    }, [location])
    return (
        <></>
    );
};

export default GoogleLogin;