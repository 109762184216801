import React from 'react';
import Header from "../common/Header";
import KeywordRankingTable from "./KeywordRankingTable";
import RecommendationKeyword from "./RecommendationKeyword";
import styles from '../../styles/search/searchContainer.module.css'
import RecentKeywordTable from "./RecentKeywordTable";

const SearchContainer = () => {
    return (
        <div>
            <Header name={'검색'}/>
            <div className={styles.tableContainer}>
                <KeywordRankingTable/>
                <RecentKeywordTable/>
            </div>
            <RecommendationKeyword/>
        </div>
    );
};

export default SearchContainer;