import React, {useEffect, useState} from 'react';
import '../../../styles/home/dashboard/calender.css';
import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const SDatePicker = styled(DatePicker)`
  border: none;
  color: #0A0A0D;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 70px;
`;
const Calender = ({
                      dateState, dateAction, isIconOn = true, maxDate = new Date(), background = null, timeSelect = false
                  }) => {
    const nowDate = new Date();


    return (
        <div className={'calenderContainer'}>
            {
                isIconOn &&
                <object className={'calenderIcon'}
                        data={'https://on-review-admin-images.s3.ap-northeast-2.amazonaws.com/icon/calendar.svg'}></object>
            }
            <div className={timeSelect?'periodContainer time':'periodContainer'}>
                <SDatePicker
                    width={timeSelect?'140px':''}
                    dateFormat={timeSelect?'yyyy.MM.dd a HH:mm':'yyyy.MM.dd'}
                    selected={dateState.startDate}
                    onChange={(date) => dateAction.setStartDate(date)}
                    selectsStart
                    startDate={dateState.startDate}
                    endDate={dateState.endDate}
                    maxDate={dateState.endDate}
                    locale={ko}
                    showTimeSelect={timeSelect}
                />
                <div>~</div>
                <SDatePicker
                    dateFormat={timeSelect?'yyyy.MM.dd a HH:mm':'yyyy.MM.dd'}
                    selected={dateState.endDate}
                    onChange={(date) => dateAction.setEndDate(date)}
                    selectsEnd
                    startDate={dateState.startDate}
                    endDate={dateState.endDate}
                    minDate={dateState.startDate}
                    maxDate={maxDate}
                    locale={ko}
                    showTimeSelect={timeSelect}
                />
            </div>
        </div>
    );
};

export default Calender;