function leftPad(value) {
    if (value >= 10) {
        return value;
    }

    return `0${value}`;
}

export function toStringByFormatting(source, delimiter = '-') {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return [year, month, day].join(delimiter);
}

export function toStringByFormattingTime(source, delimiter = '-') {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    const hour = leftPad(source.getHours());
    const minute = leftPad(source.getMinutes());

    return [year, month, day, hour, minute].join(delimiter);
}

export function toDateByString(source, delimiter = '-'){
    if(!source){
        return null;
    }
    const [year, month, day] = source.split(delimiter);
    const date = new Date();
    date.setFullYear(year)
    date.setMonth(month-1)
    date.setDate(day)
    return date;
}

export function toDateByStringTime(source, delimiter = '-'){
    if(!source){
        return null;
    }
    const [year, month, day, hour, minute] = source.split(delimiter);
    const date = new Date();
    date.setFullYear(year)
    date.setMonth(month-1)
    date.setDate(day)
    date.setHours(hour)
    date.setMinutes(minute)
    return date;
}