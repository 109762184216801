import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../styles/lectureRequest/platformModal.css';
import {ReactComponent as XClose} from "../../images/x-close.svg";
import {request} from "../../util/request";


const PlatformModal = ({
                           setIsOpenModal,
                           setLoaded
                       }) => {
    const imageInput = useRef();
    const [fileName, setFileName] = useState('강의 플랫폼 로고를 등록해주세요.');
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const changeFile = useCallback(() => {
        if (imageInput.current.files.length) {
            console.log(imageInput.current.files[0])
            setFileName(imageInput.current.files[0].name);
            setFile(imageInput.current.files[0])
        }
    }, [imageInput])

    const onClickImageUpload = useCallback(() => {
            imageInput.current.click();
        }, [imageInput]
    )

    const onClickRegister = useCallback(() => {
        if (name && file) {
            setIsLoading(true);
            const form = new FormData;
            form.append('name', name);
            form.append('file', file);
            request.post('/api/v1/platform', form)
                .then(({status}) => {
                    if (status === 201) {
                        setLoaded(false);
                        setIsOpenModal(false);
                    } else {
                        alert('플랫폼 추가 실패')
                    }
                    setIsLoading(false);
                })
                .catch((err) => {

                    setIsLoading(false)
                });
        }
    }, [name, file])

    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.key === 'Escape') {
                setIsOpenModal(false);
            }
        };
        window.addEventListener("keyup", escKeyModalClose);
        return () => window.removeEventListener("keyup", escKeyModalClose);
    }, [])

    useEffect(() => {
        if (name && file) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [name, file])
    return (
        <div className={'platformModal'}>
            <div className={'platformModalXCloseSvg'}
                 onClick={() => {
                     setIsOpenModal(false);
                 }}
            >
                <XClose></XClose>
            </div>
            <div className={'platformModalHeader'}>
                강의 플랫폼 추가하기
            </div>
            <div className={'platformModalNameContainer'}>
                <div>강의 플랫폼 이름</div>
                <input
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }
                    }
                    className={'platformModalName'}
                    placeholder={'이름을 입력해주세요.'}
                />
            </div>
            <div className={'platformModalLogoContainer'}>
                <div>강의 플랫폼 로고</div>
                <div className="platformModalFileContainer">
                    <input
                        type="file" style={{display: "none"}} ref={imageInput}
                        onChange={() => {
                            changeFile();
                        }}
                    />
                    <div>{fileName}</div>
                    <button
                        className={'platformModalFileButton'}
                        onClick={onClickImageUpload}>찾아보기
                    </button>
                </div>
            </div>
            <div className={'platformModalButtonContainer'}>
                <button
                    className={'platformModalExitButton'}
                    onClick={() => {
                        setIsOpenModal(false);
                    }}
                >
                    취소
                </button>
                <button
                    className={'platformModalAppendButton ' + (isActive ? 'active' : '')}
                    disabled={!isActive}
                    onClick={() => {
                        onClickRegister();
                    }}
                >
                    추가하기
                </button>
            </div>
        </div>
    );
};

export default PlatformModal;