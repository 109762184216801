import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/reviewPage/reviewPageContainer.module.css';
import Header from "../common/Header";
import Calender from "../home/dashboard/Calender";
import {ReactComponent as ChevronDown} from "../../images/chevron-down.svg";
import {ReactComponent as ChevronUp} from "../../images/chevron-up.svg";
import ReviewTableRow from "./ReviewTableRow";
import {toStringByFormatting} from "../../util/dateUtil";
import {request} from "../../util/request";

const ReviewPageContainer = ({
                                 dateState, dateAction
                             }) => {
    const [totalCount, setTotalCount] = useState(0);
    const [sort, setSort] = useState('latest');
    const [query, setQuery] = useState('');
    const [isDateDesc, setIsDateDesc] = useState(null);
    const [reviews, setReviews] = useState([]);

    const onClickReviewDesc = useCallback(() => {
        setReviews([...reviews.sort((a, b) => {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            if (a.date === b.date) return 0;
        })]);
        setIsDateDesc(true);
    }, [reviews])
    const removeReviewInList = useCallback((id) => {
        const newList = reviews.filter((item) => item.reviewId !== id)
        newList.forEach((ele) => console.log(id + " deleted " + ele))
        setReviews((prevState) => {
            return [...prevState.filter((item) => item.reviewId !== id)]
        });
        setTotalCount(prevState => prevState - 1)
    }, [reviews])

    const getReviews = useCallback(() => {
        request.get('/api/v1/admin/lecture/review', {
            params: {
                startDate: toStringByFormatting(dateState.startDate),
                endDate: toStringByFormatting(dateState.endDate),
                q: query,
                sort
            }
        }).then(({status, data}) => {
            if (status === 200) {
                setReviews([...data.reviews])
                setTotalCount(data.totalCount)
                setIsDateDesc(null);
            }
        })
    }, [dateState.startDate, dateState.endDate, query, sort])
    useEffect(() => {
        getReviews();
        setIsDateDesc(null);
    }, [dateState, sort])

    const onClickReviewAsc = useCallback(() => {
        setReviews([...reviews.sort((a, b) => a.date < b.date ? -1 : 1)])
        setIsDateDesc(false);
    }, [reviews])

    return (
        <div className={styles.container}>
            <>
                <Header name={'리뷰'}/>
                <div className={styles.topContainer}>
                    <div className={styles.searchContainer}>
                        <Calender dateState={dateState} dateAction={dateAction}></Calender>
                        <input
                            className={styles.searchInput} placeholder={'강의 플랫폼, 강의명, 튜터를 검색하세요.'}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    getReviews()
                                }
                            }}
                            value={query}
                        />
                        <div className={styles.totalCountContainer}>
                            총
                            <span className={styles.totalCount}>{' ' + totalCount.toLocaleString() + ' '}</span>
                            리뷰
                        </div>
                    </div>
                    <select
                        className={styles.sort}
                        onChange={(e) => {
                            setSort(e.target.value);
                        }
                        }>
                        <option value={'latest'}>최신순</option>
                        <option value={'oldest'}>등록순</option>
                    </select>
                </div>
                <div className={styles.tableHeader}>
                    <div className={styles.dateColumn}>
                        <div>날짜</div>
                        <div className={styles.sort}>
                            <div
                                className={isDateDesc === null ? '' : !isDateDesc ? styles.active : ''}
                            >
                                <ChevronUp
                                    onClick={() => {
                                        onClickReviewAsc()
                                    }}
                                ></ChevronUp>
                            </div>
                            <div
                                className={isDateDesc === null ? '' : isDateDesc ? styles.active : ''}
                            >
                                <ChevronDown
                                    onClick={() => {
                                        onClickReviewDesc()
                                    }}
                                ></ChevronDown>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tableHeaderColumn}>
                        사용자
                    </div>
                    <div className={styles.tableHeaderColumn}>
                        평점
                    </div>
                    <div className={styles.tableHeaderColumn}
                         style={{width: '400px'}}
                    >
                        리뷰 내용
                    </div>
                    <div className={styles.tableHeaderColumn}>
                        좋아요수
                    </div>
                    <div className={styles.tableHeaderColumn}>
                        신고수
                    </div>
                </div>
                {reviews.map(({
                                  reviewId,
                                  userId,
                                  lectureId,
                                  date,
                                  nickname,
                                  grade,
                                  lectureTitle,
                                  reviewContent,
                                  recommendationCount,
                                  reportCount,
                                  postCourseTarget,
                                  pinned
                              }) => (
                    <ReviewTableRow
                        key={reviewId}
                        reviewId={reviewId}
                        userId={userId}
                        lectureId={lectureId}
                        date={date}
                        nickname={nickname}
                        grade={grade}
                        lectureTitle={lectureTitle}
                        reviewContent={reviewContent}
                        recommendationCount={recommendationCount}
                        reportCount={reportCount}
                        removeReviewInList={removeReviewInList}
                        availableUserReview={true}
                        postCourseTarget={postCourseTarget}
                        pinned={pinned}
                    />
                ))}
            </>

        </div>
    );
};

export default ReviewPageContainer;