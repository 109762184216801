import React, {useCallback, useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {login} from "../../util/request";
import qs from 'qs';

const KakaoLogin = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const redirectUri = 'https://www.admin.onreview.xyz/api/auth/callback/kakao'
    // const redirectUri = 'http://localhost:3000/api/auth/callback/kakao'

    useEffect(() => {
        if (searchParams.has("code")) {
            const code = searchParams.get("code");
            console.log(code);
            (async () => {
                const kakaoToken = await getKakaoToken(code);
                if (kakaoToken == null) {
                    alert('알 수 없는 오류. 개발자에게 문의하세요')
                    return;
                }
                const res = await login(kakaoToken, "kakao")
                if (res) {
                    navigate('/')
                } else {
                    navigate('/login')
                }

            })()


        }
    }, [searchParams])

    const getKakaoToken = useCallback(async (code) => {
        const headers = {
            'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
        const url = 'https://kauth.kakao.com/oauth/token'
        const formData = {
            'grant_type': 'authorization_code',
            'client_id': '755018634543ff6cde1709f3ab672e96',
            'redirect_uri': redirectUri,
            'code': code
        }

        const {status, data} = await axios.post(url, qs.stringify(formData), {headers});
        if (status === 200) {
            return data.access_token;
        } else {
            return null;
        }
    }, [])


    return (
        <></>
    );
};

export default KakaoLogin;